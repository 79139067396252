@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
 @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');
 @import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700,800,900');
/*----------------------------------------------------*/
/*===================================================================== 
Table of Content: 
01 - TT-HEADER 
02 - BUTTON EFFECT 
03 - SWIPER SLIDER 
04 - TT-MSLIDE 
05 - SERVICES 
06 - ABOUT US 
07 - LATEST WORK 
08 - CLIENT CAROUSEL 
09 - BLOG SECTION 
10 - FOOTER-STRIP 
11 - FOOTER 
12 - HOVER EFFECTS 
13 - Contact Us =====================================================================*/
/*----------------------------------------------------*/

/*-------------------------------------------------------------------------------------------------------------------------------*/
/*01 - TT-HEADER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .header_top {
     border-top: 10px solid #f2ae2b;
     background: #f5f7fa;
}
 .header_top h4 {
     color: #898989;
     font-size: 15px;
     font-weight: 500;
     font-family: 'Poppins', sans-serif;
     margin-top: 25px;
     margin-bottom: 0;
}
 .header_top .icon i {
     color: #003366;
     font-size: 36px;
}
 .header_top .icon {
     display: inline-block;
     padding-right: 15px;
}
 .header_top .text {
     display: inline-block;
}
 .header_top ul {
     padding-left: 0;
     height: 62px;
     background: #fff;
     width: 475px;
     float: right;
     margin-top: -10px;
     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
 .header_top ul li {
     list-style: none;
     float: left;
     margin-left: 30px;
}
 .header_top .text h5 {
     color: #003366;
     font-size: 14px;
     font-weight: 600;
     font-family: 'Poppins', sans-serif;
}
.header_top .text h6 a, .header_top .text h6 {
    color: #f2c21a;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.header2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
}
.tt-header {
     width: 100%;
     background: #003366;
}
 .tt-header ul {
     padding-left: 0;
     margin-bottom: 0;
}
 .tt-header .logo img {
     display: inline-block;
     max-width: 100%;
     max-height: 100%;
     width: auto;
     height: auto;
     vertical-align: middle;
}
/*general navigation*/
 .tt-header .main-nav>ul>li>a {
     font-family: 'Poppins', sans-serif;
     font-size: 15px;
     line-height: 28px;
     font-weight: 500;
     color: #434343;
     letter-spacing: 0.30px;
     text-transform: initial;
     transition: none;
}
 .tt-header .main-nav>ul>li>a {
     color: #fff;
     text-decoration: none;
}
 .tt-header .main-nav>ul>li.active>a, .tt-header .main-na >ul>li:hover>a {
     color: #f2ae2b;
     text-decoration: none;
}
/*nav-more*/
 .tt-header .nav-more {
     display: inline-block;
     font-size: 0;
     padding-top: 40px;
     padding-bottom: 30px;
     margin-left: 12px;
     transition: none;
}
/*top line*/
 .tt-header .top-line-inner {
     border-bottom: 1px solid #d9d9d9;
}
 .tt-header .top-line-left {
     float: left;
}
 .tt-header .top-line-right {
     float: right;
}
 .tt-header .top-line-left:after, .tt-header .top-line-right:after {
     content: '';
     display: inline-block;
     width: 0;
     height: 48px;
     vertical-align: middle;
}
 .tt-header .top-info {
     display: inline-block;
     position: relative;
     font-size: 15px;
     letter-spacing: 0.3px;
     padding-right: 20px;
     margin-right: 20px;
     vertical-align: middle;
}
 .tt-header .top-info:before {
     content: '';
     display: block;
     position: absolute;
     top: 50%;
     right: 0;
     width: 1px;
     height: 16px;
     margin-top: -8px;
     background: #adadad;
}
 .tt-header .top-info:last-child {
     margin-right: 0;
     padding-right: 0;
}
 .tt-header .top-info:last-child:before {
     display: none;
}
 .tt-header .top-info a:hover {
     color: #242424;
}
 .tt-header .top-social li {
     display: inline-block;
     margin-right: 12px;
}
 .tt-header .top-social li:last-child {
     margin-right: 0;
}
 .tt-header .top-social a:hover {
     color: #30c3f4;
}
 body {
     overflow-x: hidden;
}
/*cmn-toggle-switch*/
 .cmn-toggle-switch:focus {
     outline: none;
}
 .tt-header .cmn-toggle-switch {
     display: none;
     position: relative;
     float: right;
     margin-top: 12px;
     padding: 0;
     width: 25px;
     height: 17px;
     cursor: pointer;
     z-index: 101;
}
 .tt-header .cmn-toggle-switch span {
     display: block;
     position: absolute;
     top: 7px;
     left: 0;
     right: 0;
     height: 3px;
     background: #003366;
     -webkit-transition: all 300ms ease-in-out;
     -moz-transition: all 300ms ease-in-out;
     transition: all 300ms ease-in-out;
}
 .tt-header .cmn-toggle-switch::before, .tt-header .cmn-toggle-switch::after {
     content: "";
     position: absolute;
     display: block;
     top: 7px;
     left: 0;
     width: 100%;
     height: 3px;
     background: #003366;
     -webkit-transition: all 300ms ease-in-out;
     -moz-transition: all 300ms ease-in-out;
     transition: all 300ms ease-in-out;
}
 .tt-header .cmn-toggle-switch::before {
     margin-top: -7px;
}
 .tt-header .cmn-toggle-switch::after {
     margin-top: 7px;
}
 .tt-header .cmn-toggle-switch.active::before {
     -webkit-transform: translateY(7px) rotate(45deg);
     -moz-transform: translateY(7px) rotate(45deg);
     -ms-transform: translateY(7px) rotate(45deg);
     transform: translateY(7px) rotate(45deg);
     -webkit-transform-origin: center center;
     -moz-transform-origin: center center;
     -ms-transform-origin: center center;
     transform-origin: center center;
}
 .tt-header .cmn-toggle-switch.active::after {
     -webkit-transform: translateY(-7px) rotate(-45deg);
     -moz-transform: translateY(-7px) rotate(-45deg);
     -ms-transform: translateY(-7px) rotate(-45deg);
     transform: translateY(-7px) rotate(-45deg);
     -webkit-transform-origin: center center;
     -moz-transform-origin: center center;
     -ms-transform-origin: center center;
     transform-origin: center center;
}
 .tt-header .cmn-toggle-switch.active span {
     opacity: 0;
}
 .tt-header-margin {
     height: 149px;
}
 @media (min-width: 992px) {
     .tt-header .logo {
         display: inline-block;
         position: relative;
         z-index: 101;
         transition: none;
    }
     .tt-header .top-inner {
         position: relative;
         float: left;
         padding: 15px 0;
    }
     .tt-header .toggle-block {
         display: block;
         float: right;
    }
     .tt-header .toggle-block .container {
         width: 100%;
         padding-left: 0;
         padding-right: 0;
    }
     .tt-header .main-nav {
         display: inline-block;
         vertical-align: top;
    }
     .tt-header .main-nav>ul>li {
         position: relative;
         float: left;
         list-style: none;
    }
     .tt-header .main-nav>ul>li.active {
         color: #f2ae2b;
    }
     .tt-header .main-nav>ul>li>a {
         display: block;
         position: relative;
         padding: 36px 14px;
    }
     .tt-header .main-nav>ul>li:last-child>a:before {
         display: none;
    }
    /*2nd level*/
     .tt-header .main-nav>ul>li>ul {
         display: block;
         position: absolute;
         top: 100%;
         left: 0;
         width: 256px;
         background: #f1f1f1;
         visibility: hidden;
         opacity: 0;
         -webkit-transform: translateY(40px);
         -moz-transform: translateY(40px);
         -ms-transform: translateY(40px);
         transform: translateY(40px);
         -webkit-transition: all 300ms ease-in-out;
         -moz-transition: all 300ms ease-in-out;
         transition: all 300ms ease-in-out;
    }
     .tt-header .main-nav>ul>li:hover>ul {
         visibility: visible;
         opacity: 1;
         -webkit-transform: translateY(0px);
         -moz-transform: translateY(0px);
         -ms-transform: translateY(0px);
         transform: translateY(0px);
    }
     .tt-header .main-nav>ul>li>ul>li {
         border-bottom: 1px solid #dddddd54;
         text-align: left;
         list-style: none;
    }
     .tt-header .main-nav>ul>li>ul>li:last-child {
         border-bottom: 0;
    }
     .tt-header .main-nav>ul>li>ul>li>a {
         display: block;
         position: relative;
         font-size: 16px;
         line-height: 18px;
         font-weight: 400;
         color: #898989;
         letter-spacing: 0.30px;
         padding: 15px 19px 14px 31px;
         text-decoration: none;
         font-family: 'Poppins', sans-serif;
    }
    /*3rd level*/
     .tt-header .main-nav>ul>li>ul>li {
         position: relative;
    }
     .tt-header .main-nav>ul>li>ul>li .menu-toggle{
         display:none;
    }
     .tt-header .main-nav>ul>li>ul>li>ul {
         display: block;
         position: absolute;
         top: 0;
         left: 100%;
         width: 256px;
         background: #f1f1f1;
         visibility: hidden;
         opacity: 0;
         -webkit-transform: translateY(40px);
         -moz-transform: translateY(40px);
         -ms-transform: translateY(40px);
         transform: translateY(40px);
         -webkit-transition: all 300ms ease-in-out;
         -moz-transition: all 300ms ease-in-out;
         transition: all 300ms ease-in-out;
         list-style: none;
    }
     .tt-header .main-nav>ul>li:nth-last-child(1)>ul>li>ul, .tt-header .main-nav>ul>li:nth-last-child(2)>ul>li>ul {
         left: auto;
         right: 100%;
         margin-right: 0;
    }
     .tt-header .main-nav>ul>li>ul>li:hover>ul {
         visibility: visible;
         opacity: 1;
         -webkit-transform: translateY(0px);
         -moz-transform: translateY(0px);
         -ms-transform: translateY(0px);
         transform: translateY(0px);
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li:last-child {
         border-bottom: 0;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li {
         border-bottom: 1px solid #dddddd54;
         text-align: left;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a {
         display: block;
         position: relative;
         font-size: 16px;
         line-height: 18px;
         font-weight: 500;
         color: #898989;
         letter-spacing: 0.30px;
         padding: 15px 19px 14px 31px;
         font-family: 'Poppins', sans-serif;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a:hover {
         background: #003366;
         color: #fff;
         text-decoration: none;
    }
     .tt-header .menu-toggle {
         display: inline-block;
         padding-left: 3px;
    }
    /*stick*/
     .header2.stick {
         position: fixed;
         left: 0;
         width: 100%;
    }
     .header2.stick .logo {
         height: 42px;
         line-height: 42px;
    }
     .header2.stick .nav-more {
         padding: 16px 0;
    }
     .tt-header .top-line-right {
         float: right;
         padding-top: 10px;
         vertical-align: middle;
    }
     .responsive_logo {
         display: none;
    }
    .header2 {
        position: relative;
    }
    .tt-header {
        position: relative;
        background: #f5f7fa;
    }
     .header2.stick {
         position: fixed;
         box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    }
     .tt-header .main-nav>ul>li>a {
         font-weight: 600;
         font-size: 14px;
         color: #898989;
         text-transform: uppercase;
         padding: 30px 13px;
         font-family: 'Poppins', sans-serif;
         letter-spacing: 0.30px;
    }
     .tt-header .main-nav>ul>li.last a{
         padding-right: 0;
    }
     .tt-header .main-nav>ul>li:hover {
         color: #f2ae2b;
    }
     .tt-header .toggle-block {
         display: block;
         float: right;
    }
     .tt-header .top-inner {
         float: left;
         padding-bottom: 0;
    }
     .tt-header .top-info a {
         color: #f2c21a;
         font-size: 18px;
    }
     .tt-header .top-info a:hover {
         color: #fff;
    }
     .tt-header .nav-more {
         padding: 22px 0px;
    }
     .tt-header .top-info {
         padding-right: 0;
         margin-right: 0;
    }
     .tt-header .top-social li {
         display: inline-block;
         margin-right: 18px;
    }
     .tt-header .fa-search {
         padding-top: 4px;
         height: 26px;
         width: 1px;
         border-left: 1px solid #48474b;
         margin-top: 0px;
    }
     .tt-header .fa-search {
         color: #fff;
         font-size: 15px;
    }
     .tt-header .nav-more {
         padding: 16px 0px;
    }
     .tt-header .fa-search:before {
         padding-left: 18px;
    }
     .tt-header .top-social li:last-child {
         display: inline-block;
         margin-right: 0px;
    }
     .tt-header .main-nav>ul>li.first a {
         padding-left: 0;
    }
     .tt-header .main-nav>ul>li.active ul li a {
         color: #898989;
         padding-left: 20px;
    }
     .tt-header .main-nav>ul>li>ul>li>a:hover {
         background: #003366;
         color: #fff;
    }
}
 @media (max-width: 1199px) and (min-width: 992px) {
     .tt-header .logo {
         width: 200px;
    }
     .tt-header .main-nav>ul>li>a {
         letter-spacing: 0;
         padding-left: 9px;
         padding-right: 9px;
    }
}
 @media (max-width: 991px) {
    .header-2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: auto;
        overflow-x: hidden;
        -webkit-transition: width 1s, height 1s, transform 1s;
        -moz-transition: width 1s, height 1s, transform 1s;
        transition: width 1s, height 1s, transform 1s;
    }
     .tt-header {
         height: auto;
         overflow-x: hidden;
         background:#f5f7fa;
    }
     .tt-header .toggle-block{
         background:#003366;
    }
     .header2.stick {
         position: fixed;
    }
     .tt-header .top-line {
         position: relative;
         background: #f6f6f6;
         text-align: left;
         z-index: 2;
    }
     .tt-header .top-line-inner {
         border: 0;
    }
     .tt-header .logo {
         height: 46px;
         line-height: 46px;
    }
     .tt-header .top-inner {
         position: relative;
         padding: 15px 0;
         text-align: left;
         z-index: 1;
    }
     .tt-header .top-inner:before, .tt-header .top-inner:after {
         content: '';
         display: block;
         position: absolute;
         top: 0;
         left: -500px;
         width: 500px;
         height: 100%;
         background: #f5f7fa;
    }
     .tt-header .top-inner:after {
         left: auto;
         right: -500px;
    }
     .tt-header .top-inner {
         margin-top: 0px;
         float: none;
    }
     .tt-header .cmn-toggle-switch {
         display: block;
    }
     .tt-header .toggle-block {
         display: none;
         text-align: center;
         position: relative;
         top: 0;
         left: 0;
         width: 100%;
         max-height: 100%;
         overflow: auto;
         padding: 0px 0 0 0;
    }
     .tt-header .main-nav {
         margin-bottom: 0;
         text-align: left;
    }
     .tt-header .main-nav>ul>li {
         border-bottom: 1px solid #cccccc2e;
         overflow: hidden;
    }
     .tt-header .main-nav>ul>li>a, .tt-header .main-nav>ul>li>a:focus {
         display: block;
         position: relative;
         width: 750px;
         font-size: 15px;
         line-height: 20px;
         color: #fff;
         padding: 13px 15px 13px 15px;
         margin: 0 auto;
    }
     .tt-header .main-nav>ul>li>a:before, .tt-header .main-nav>ul>li>a:after {
         content: '';
         display: block;
         position: absolute;
         top: 0;
         left: -500px;
         width: 500px;
         bottom: 0;
         background: transparent;
         -webkit-transition: all 300ms ease-in-out;
         -moz-transition: all 300ms ease-in-out;
         transition: all 300ms ease-in-out;
    }
     .tt-header .main-nav>ul>li>a:after {
         left: auto;
         right: -500px;
    }
     .tt-header .main-nav>ul>li>a:hover, .tt-header .main-nav>ul>li.active>a, .tt-header .main-nav>ul>li.select>a {
         color: #fff;
         background: #f2c21a;
         text-decoration: none;
    }
     .tt-header .main-nav>ul>li.select>a .menu-toggle {
         -webkit-transform: rotate(180deg);
         -moz-transform: rotate(180deg);
         -ms-transform: rotate(180deg);
         transform: rotate(180deg);
    }
     .tt-header .main-nav>ul>li>a:hover:before, .tt-header .main-nav>ul>li>a:hover:after, .tt-header .main-nav>ul>li.active>a:before, .tt-header .main-nav>ul>li.active>a:after, .tt-header .main-nav>ul>li.select>a:before, .tt-header .main-nav>ul>li.select>a:after {
         background: #003366;
    }
     .tt-header .main-nav>ul>li>ul {
         display: none;
         background: #003366;
         border-top: 1px solid #cccccc2e;
    }
     .tt-header .main-nav>ul>li>ul>li {
         border-bottom: 1px solid #cccccc2e;
    }
     .tt-header .main-nav>ul>li>ul>li>a {
         display: block;
         width: 750px;
         font-size: 15px;
         line-height: 20px;
         color: #fff;
         padding: 13px 22px;
         margin: 0 auto;
         font-family: 'Poppins', sans-serif;
         font-weight: 500;
    }
     .tt-header .main-nav>ul>li>ul>li>a span:before {
         content: '-';
         margin-right: 10px;
    }
     .tt-header .main-nav>ul>li>ul>li>a, .tt-header .main-nav>ul>li>ul>li>a:focus {
         display: block;
         position: relative;
         text-decoration: none;
    }
     .tt-header .main-nav>ul>li>ul>li>a:before, .tt-header .main-nav>ul>li>ul>li>a:after {
         content: '';
         display: block;
         position: absolute;
         top: 0;
         left: -500px;
         width: 500px;
         bottom: 0;
         background: transparent;
         -webkit-transition: all 300ms ease-in-out;
         -moz-transition: all 300ms ease-in-out;
         transition: all 300ms ease-in-out;
    }
     .tt-header .main-nav>ul>li>ul>li>a:after {
         left: auto;
         right: -500px;
    }
     .tt-header .main-nav>ul>li>ul>li>a:hover, .tt-header .main-nav>ul>li>ul>li.active>a, .tt-header .main-nav>ul>li>ul>li.select>a {
         color: #fff;
         background: #f2c21a;
    }
     .tt-header .main-nav>ul>li>ul>li>a:hover:before, .tt-header .main-nav>ul>li>ul>li>a:hover:after, .tt-header .main-nav>ul>li>ul>li.active>a:before, .tt-header .main-nav>ul>li>ul>li.active>a:after, .tt-header .main-nav>ul>li>ul>li.select>a:before, .tt-header .main-nav>ul>li>ul>li.select>a:after {
         background: rgba(0, 0, 0, 0.55);
    }
     .tt-header .main-nav>ul>li>ul>li.select>a .menu-toggle {
         -webkit-transform: rotate(180deg);
         -moz-transform: rotate(180deg);
         -ms-transform: rotate(180deg);
         transform: rotate(180deg);
    }
     .tt-header .main-nav>ul>li>ul>li>ul {
         display: none;
         background: #003366;
         border-top: 1px solid #cccccc2e;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li {
         border-bottom: 1px solid #cccccc2e;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a {
         display: block;
         position: relative;
         width: 750px;
         font-size: 15px;
         line-height: 20px;
         font-weight: 500;
         color: #fff;
         background: #003366;
         padding: 12px 40px;
         margin: 0 auto;
         font-family: 'Poppins', sans-serif;
         text-decoration: none;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a:before, .tt-header .main-nav>ul>li>ul>li>ul>li>a:after {
         content: '';
         display: block;
         position: absolute;
         top: 0;
         left: -500px;
         width: 500px;
         bottom: 0;
         background: transparent;
         -webkit-transition: all 300ms ease-in-out;
         -moz-transition: all 300ms ease-in-out;
         transition: all 300ms ease-in-out;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a:after {
         left: auto;
         right: -500px;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a span:before {
         content: '-';
         margin-right: 10px;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a:hover, .tt-header .main-nav>ul>li>ul>li>ul>li.active>a {
         text-decoration: none;
         color: #fff;
         background: #f2c21a;
    }
     .tt-header .main-nav>ul>li>ul>li>ul>li>a:hover:before, .tt-header .main-nav>ul>li>ul>li>ul>li>a:hover:after, .tt-header .main-nav>ul>li>ul>li>ul>li.active>a:before, .tt-header .main-nav>ul>li>ul>li>ul>li.active>a:after {
         background: #fff;
    }
     .tt-header .menu-toggle {
         position: absolute;
         top: 50%;
         right:25px;
         padding: 13px 16px;
         margin-top: -20px;
         -webkit-transition: all 300ms ease-in-out;
         -moz-transition: all 300ms ease-in-out;
         transition: all 300ms ease-in-out;
    }
     .tt-header-margin {
         height: 112px;
    }
     .tt-header .nav-more {
         margin-left: 0;
         padding-top: 0;
         display: none;
    }
     .header2.stick {
         top: 0;
         box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    }
     .header2.stick .main-nav {
         margin-top: 0px;
    }
     .header2.stick .toggle-block {
         padding-top: 0;
         -webkit-transition: padding 300ms ease-in-out;
         -moz-transition: padding 300ms ease-in-out;
         transition: padding 300ms ease-in-out;
    }
     .header1 .top-social {
         display: none;
    }
}
 @media (max-width: 767px) {
     .tt-header .top-line {
         min-height: 113px;
    }
     .tt-header .top-line-inner {
         padding: 20px 0;
    }
     .tt-header .top-line-left {
         margin-bottom: 15px;
    }
     .tt-header .top-line-left, .tt-header .top-line-right {
         float: none;
    }
     .tt-header .top-line-left:after, .tt-header .top-line-right:after {
         display: none;
    }
     .tt-header .top-info {
         display: block;
         font-size: 14px;
         margin-right: 0;
         padding-right: 0;
         margin-bottom: 10px;
    }
     .tt-header .top-info:last-child {
         margin-bottom: 0;
    }
     .tt-header .top-info:before {
         display: none;
    }
     .tt-header .main-nav>ul>li>a, .tt-header .main-nav>ul>li>a:focus, .tt-header .main-nav>ul>li>ul>li>a {
         width: auto;
         text-decoration: none;
    }
     .tt-header-margin {
         height: 177px;
    }
     .header2.stick {
         top: 0;
    }
     .tt-header .menu-toggle {
         right:9px;
    }
}
/* ------------------- */
 .tt-header .top-line {
     border-bottom: 1px solid #9e958c;
}
 .tt-header .top-line-inner {
     border-bottom: 0;
}
 .tt-header .top-line-left {
     color: #fff;
}
 .tt-header .main-nav>ul>li.active>a {
     color: #f2ae2b;
}
 .tt-header .c-btn, .c-btn:focus {
     border-radius: 0;
     background: #676cb8;
}
 .tt-header .top-info:before {
     background: #fff;
}
 .tt-header .top-info {
     font-size: 14px;
}
 .tt-header .fa-search {
     color: #fff;
     font-size: 20px;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 02 - BUTTON EFFECT */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .wrapper-inner-tab-backgrounds-first {
     float: left;
     width: 100%;
}
 .wrapper-inner-tab-backgrounds-first:hover a {
     text-decoration: none;
}
 .wrapper-inner-tab-backgrounds-second {
     float: left;
     width: 100%;
}
 .sim-button {
     line-height: 49px;
     height: 49px;
     text-align: center;
     margin-right: auto;
     margin-left: auto;
     width: 172px;
     cursor: pointer;
     font-family: 'Poppins', sans-serif;
     font-size: 15px;
     font-weight: 700;
     text-transform: uppercase;
     background: #e9d480;
     text-transform: initial;
     letter-spacing:0.30px;
}
 .bluebtn {
     margin-left: 20px;
}
 .sim-button.bluebtn {
     background: #003366;
     color: #fff;
}
 .sim-button.bluebtn:hover {
     background: #f2c21a;
     color: #2a2a2a;
}
 .sim-button.yellowbtn {
     color: #2a2a2a;
}
 .sim-button.yellowbtn:hover {
     color: #fff;
}
 .yellowbtn:hover {
     color: #fff;
}
 .button8 {
     color: rgba(255, 255, 255, 1);
     -webkit-transition: all 0.5s;
     -moz-transition: all 0.5s;
     -o-transition: all 0.5s;
     transition: all 0.5s;
     position: relative;
}
 .button8 a {
     color:#fff;
     text-decoration: none;
     display: block;
     position: relative;
     z-index: 2;
}
.button8:hover a {
    text-decoration: none;
    color: #fff;
} 
 .button8 span {
     z-index: 2;
     display: block;
     position: absolute;
     width: 100%;
     height: 100%;
}
 .button8::before {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 0%;
     height: 100%;
     z-index: 1;
     opacity: 0;
     background-color: #354a50;
     -webkit-transition: all 0.3s;
     -moz-transition: all 0.3s;
     -o-transition: all 0.3s;
     transition: all 0.3s;
}
 .button8:hover::before {
     opacity: 1;
     width: 100%;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*03 - SWIPER SLIDER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .swiper-button-disabled+.swiper-button-disabled+.swiper-wrapper {
     -moz-transform: none;
     -ms-transform: none;
     transform: none;
     -webkit-transform: none;
     cursor: auto;
}
 .swiper-button-disabled+.swiper-button-disabled+.swiper-wrapper+.swiper-pagination-bullets {
     display: none;
}
 .swiper-slide {
     position: relative;
}
 .swiper-pagination .swiper-pagination-bullet {
     width: 14px;
     height: 14px;
     background: #8e8d8d;
     opacity: 1;
}
 .swiper-pagination .swiper-pagination-bullet-active {
     background: #3695cc;
}
/*swiper-pagination-white*/
 .swiper-pagination-white .swiper-pagination-bullet {
     background: #fff;
}
 .swiper-pagination-white .swiper-pagination-bullet-active {
     background: #043742;
}
/*swiper position*/
 .swiper-pagination.fixed {
     bottom: 32px;
}
 .swiper-pagination.fixed.pos-2 {
     bottom: 15px;
}
 .swiper-pagination.relative-pagination {
     position: relative;
     top: 0;
     margin-top: 30px;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 04 - TT-MSLIDE */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .hmslider1 .slider_content h5 {
     color: #2a2a2a;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     font-size: 16px;
     text-transform: uppercase;
     text-align: left;
     margin-top: 0;
     margin-bottom: 5px;
}
 .tt-mslide-title h3 {
     color: #f2ae2b;
     font-size: 48px;
     font-weight: 600;
     font-family: 'Poppins', sans-serif;
     text-align: center;
     margin-top: 0;
     margin-bottom: 15px;
}
 .tt-mslide-title h3 span {
     color: #fff;
}
 .tt-mslide-title p {
     color: #fff;
     font-size: 15px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
     letter-spacing: 0.30px;
     line-height: 26px;
     text-align: center;
     margin-bottom: 30px;
}
 .hmslider1 .slider_content {
     width: 47.5%;
     float: none;
     margin: 0 auto;
     background: rgba(7, 7, 7, 0.40);
     padding-bottom: 240px;
     padding-top: 240px;
}
 .slider_content .sim-button {
     float: left;
}
 .tt-mslide-wrapper {
     position: relative;
     overflow: hidden;
}
 .tt-mslide {
     position: relative;
     overflow: hidden;
}
 .tt-mslide-entry {
     display: table-cell;
     width: 99999px;
     height: 694px;
     text-align: center;
     padding-bottom: 210px;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
     background-position: center center;
     vertical-align: bottom;
}
 .hmslider1 .tt-mslide:before {
     display: none;
}
 .tt-mslide:before {
     content: '';
     display: block;
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: #000;
     opacity: 0.25;
}
 .tt-mslide-border {
     position: absolute;
     bottom: 0;
     left: 50%;
     width: 1090px;
     height: 64px;
     margin-left: -545px;
     background: url(../img/mslider/border.png) no-repeat center center;
     z-index: 1;
}
 .tt-mslide-border:before, .tt-mslide-border:after {
     content: '';
     display: block;
     position: absolute;
     top: 0;
     left: -3000px;
     width: 3000px;
     height: 100%;
     background: #fff;
}
 .tt-mslide-border:after {
     left: auto;
     right: -3000px;
}
 .tt-mslide-title {
     color: #fff;
     margin-bottom: 10px;
     font-size: 60px;
     font-family: 'Poppins', sans-serif;
     text-transform: initial;
     text-align: center;
     font-weight: 500;
}
 .tt-mslide-entry .simple-text {
     margin-bottom: 35px;
}
 .tt-mslide .text-animation {
     visibility: hidden;
}
 .tt-mslide .text-animation.animated {
     visibility: visible;
}
 @media (max-width: 1199px) {
     .tt-mslide-entry {
         height: 650px;
         padding-bottom: 160px;
    }
}
 @media (max-width: 991px) {
     .tt-mslide-entry {
         height: 520px;
         padding-bottom: 100px;
    }
}
 @media (max-width: 767px) {
     .tt-mslide-entry {
         height: 425px;
         padding: 50px 0;
         vertical-align: middle;
         padding-bottom: 0;
    }
}
/*hmslider1 css */
 .hmslider1 h4 {
     font-family: 'Poppins', sans-serif;
     color: #ffffff;
     font-size: 24px;
     font-weight: 400;
     margin-top: 0;
     margin-bottom: 0px;
}
/*technologies section css */
 .sub-title {
     color: #354a50;
     font-size: 18px;
     font-weight: 700;
     font-family: 'Poppins', sans-serif;
     text-align: center;
     margin-top: 0;
     margin-bottom: 15px;
}
 .technologies i {
     color: #898989;
     font-size: 36px;
}
 .technologies .icon {
     text-align: center;
}
 .simple-text p {
     color: #354a50;
     font-size: 14px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
     text-align: center;
     line-height: 24px;
     margin-bottom: 0;
}
 .technologies .simple-text p {
     padding-bottom: 15px;
}
 .technologies .single-sec {
     padding: 50px 30px;
}
 .technologies .sub-title{
     margin-top:15px;
}
 .technologies{
     box-shadow: 0 8px 43px rgba(0, 0, 0, 0.2);
}
.technologies .sim-button {
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
}

 .strip {
     background: #e9d480;
     padding: 50px;
}
 .strip h2 {
     color: #354a50;
     font-size: 36px;
     font-weight: 500;
     font-family: 'Poppins', sans-serif;
     text-align: center;
     margin-top: 0;
     margin-bottom: 20px;
}
 .strip h4 {
     color: #354a50;
     font-size: 14px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
     text-align: center;
}
 .tt-title {
     color: #354a50;
     font-size: 36px;
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     margin-top: 0;
     margin-bottom: 15px;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 05 - SERVICES */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .services_01 {
     padding: 100px 0;
     background-size: cover;
     background-attachment:fixed;
	 background-position: center;
}
 .services_01 .simple-text p {
     width: 580px;
     margin: 0 auto;
}
 .services_01 .tt-title {
     text-align: center;
}
 .services_01 .single_service {
     margin-top: 50px;
}
 .services_01 .single_service .content {
     border: 1px solid #dfdfdf;
     padding: 40px 30px;
     background: #fff;
     padding-bottom: 70px;
     box-shadow: 0 8px 43px rgba(0, 0, 0, 0.1);
}
 .single_service .sub-title, .single_service .simple-text p {
    text-align: left;
 }
 .single_service .sub-title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
 }
 .services_01 .single_service img {
   width: 100%;
   height: auto;
}
 .services_01 .single_service .content .simple-text p {
     width: auto;
}
 .services_01 .single_service .content .sim-button {
     margin-top: 45px;
}
 .services_01 .owl-nav{
     display:none;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 06 - ABOUT US */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .about_us_01 {
     padding-top: 250px;
}
 .about_us_01 .simple-text {
     width: 580px;
     margin: 0 auto;
}
 .blue_strip{
     height: 98px;
     background: #003365;
}
 .about_us_01 .simple-text p{
     margin-bottom:50px;
}
 .img_width{
     width:100%;
}
 .about_us_01 .sub-title{
     text-align:left;
}
 .center{
     text-align:center;
}
 .left{
     text-align:left;
}
 .about_us_01 .our_vision .simple-text{
     width: 100%;
     margin:auto;
}
 .about_us_01 .our_vision .simple-text p{
     margin-bottom:30px; 
	 text-align: left;
}
 .about_us_01 .our_mission .simple-text{
     width: 100%;
     margin:auto;
}
 .about_us_01 .our_mission .simple-text p{
     margin-bottom:20px;
	 text-align: left;
}
 .about_us_01 .our_mission .sim-button{
     text-transform:initial;
     font-weight:500;
     letter-spacing:0.30px;
     margin: 0;
     font-size: 16px;
}
 .about_us_01 .our_vision_h4 {
     font-size: 15px;
     font-family: 'Poppins', sans-serif;
     font-weight: 400;
     color: #ffffff;
     background-color: #f2ae2b;
     padding: 15px 20px 15px 20px;
     border-radius: 3px;
     margin-top:0;
     margin-bottom:10px;
}
 .about_us_01 .our_vision_p2{
     width: 230px;
     padding-left:10px;
}
 .about_us_01 .our_vision_02 .simple-text p{
     margin-bottom:0;
	 text-align: left;
}
/* about_us_features section css */
 .about_us_features .about_us1 {
     border:2px solid #f5f7fa;
}
 .about_us_features .about_us1 h2{
     font-size:72px;
     text-align:center;
     font-weight:900;
     font-family: 'Poppins', sans-serif;
     color:#000000;
     opacity:0.1;
     margin-top:-17px;
	 visibility:hidden;
}
 .about_us_features .about_us1 h3{
     padding-left:40px;
     font-size:16px;
     color:#003366;
     font-weight:600;
     font-family: 'Poppins', sans-serif;
     margin-top:-40px;
     position:relative;
     padding-left:54px;
     margin-bottom: 15px;
}
 .about_us_features .about_us1 .icofont{
     font-size:36px;
     color:#f2ae2b;
     position:absolute;
}
 .about_us_features .about_us1 p{
     font-size:15px;
     font-family: 'Poppins', sans-serif;
     margin-left:40px;
     padding-bottom:33px;
     margin-bottom:0;
     color:#898989;
     padding-left:15px;
     line-height: 24px;
}
 .about_us_features .content{
     padding: 0 25px 0 15px;
}
 .about_us_features{
     padding-top:70px;
}
 .hmslider1 .tt-mslide-entry {
     height: auto;
     padding-bottom: 0;
}
 .hmslider1 .whitebtn{
     border:0;
     margin:0 auto;
}
 .hmslider1 .sim-button{
     float:none;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 07 - LATEST WORK */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .cellpadding {
     padding: 50px 0 80px 0;
}
 .latest_work_01 .simple-text p {
     width: 580px;
     margin: 0 auto;
}
 .plr0 {
     padding: 0;
}
 .portfolio-item img {
     width: 100%;
}
 .latest_work_01 .row0 {
     margin-bottom: 50px;
}
 .portfolio-sorting li a {
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     color: #8f8f8f;
     font-weight: 600;
     text-transform: uppercase;
     text-decoration: none;
}
 ul.portfolio-sorting {
     margin-bottom: 40px;
}
 .portfolio-sorting.list-inline>li {
     display: inline-block;
     padding: 8px 15px;
     border: 1px solid #dddddd;
     border-radius: 3px;
     margin-right: 15px;
}
 .portfolio-sorting.list-inline>li:hover{
     background:#f2ae2b;
}
 .portfolio-sorting.list-inline>li:hover a{
     color:#fff;
}
 .portfolio-sorting.list-inline>li:hover a.active{
}
 .portfolio-sorting.list-inline>li:last-child {
     margin-right: 0;
}
 .whitebtn {
     width: 154px;
     height: 43px;
     line-height: 43px;
     border-radius: 25px;
     background: #fff;
     border: 2px solid #003366;
     color: #003366;
     font-family: 'Poppins', sans-serif;
     font-weight:600;
}
 .whitebtn:hover {
     color: #fff;
     border-radius: 25px;
}
 .button8.whitebtn::before {
     border-radius: 25px;
}
 .latest_work_01 .sim-button {
     margin-top: 50px;
     text-transform: initial;
	 margin-bottom: 90px;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 08 - CLIENT CAROUSEL */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .client_say_01 {
	 background: url(http://via.placeholder.com/1920x3744) no-repeat;
     background-size: cover;
     position: relative;
     clear: both;
     background-attachment:fixed;
     z-index: 111;
	 background-position: center;
}
 .client_say_01:before {
     background: #003366;
     opacity: 0.75;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     content: '';
}
 .client_say_01 .content {
     position: relative;
     margin-bottom: 40px;
}
 .client_say_01 h5 {
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     font-size: 17px;
     color: #fff;
     width: 800px;
     text-align: center;
     margin: 0 auto;
     line-height: 24px;
}
 .client_say_01 .owl-carousel .owl-item img {
     width: auto;
     margin: 0 auto;
     margin-top: 25px;
}
 .white {
     color: #fff;
}
 .client_say_01 .simple-text p {
     color: #ffffff;
     width: 580px;
     margin: 0 auto;
}
 .client_say_01 .icon {
     color: #f2ae2b;
     font-size: 48px;
     text-align: center;
}
 .client_say_01 h4 {
     color: #f2ae2b;
     font-size: 18px;
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     text-align: center;
     margin-top: 12px;
     margin-bottom: 0;
}
 .client_say_01 .owl-nav i {
     font-size: 48px;
     color: #ffffff;
}
 .client_say_01 .owl-prev {
     position: absolute;
     left: 0;
     top: 50%;
}
 .client_say_01 .owl-next {
     position: absolute;
     right: 0;
     top: 50%;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 09 - BLOG SECTION */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .latest_blog_01 .simple-text p {
     width: 580px;
     margin: 0 auto;
     margin-bottom: 50px;
}
 .latest_blog_01 .content h4 {
     font-family: 'Poppins', sans-serif;
     font-size: 14px;
     font-weight: 400;
     color: #f2ae2b;
     margin-top: 0;
     margin-bottom: 15px;
}
 .latest_blog_01 .content .date {
     color: #898989;
     font-family: 'Poppins', sans-serif;
     font-size: 13px;
     font-weight: 400;
     text-align: right;
     margin-top: 0;
     margin-bottom: 20px;
}
 .latest_blog_01 img {
     width: 100%;
}
 .latest_blog_01 .sub-title {
     text-align: left;
     line-height: 26px;
     margin-bottom: 5px;
}
 .latest_blog_01 .content {
     box-shadow: 0px 8px 43px rgba(0, 0, 0, 0.1);
     padding: 40px 25px 40px 25px;
}
 .latest_blog_01 .content .sim-button {
     margin-top: 15px;
     font-size: 16px;
     text-transform: initial;
     font-weight: 500;
}
/* client_carousel_01 section css */
 .client_carousel_01 {
     background: #f5f7fa;
}
 .client_carousel_01 .simple-text p {
     width: 580px;
     margin: 0 auto;
}
 .client_carousel_01 .owl-carousel .owl-item img {
     width: auto;
     margin:0 auto;
}
 .client_carousel_01 .item {
     height: auto;
     vertical-align: middle;
     display: block;
     margin-top:25px;
}
 .client_carousel_01 .owl-carousel2{
     margin-top:20px;
}
 .client_carousel_01 .owl-nav{
     display:none;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 10 - FOOTER-STRIP */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .footer_strip {
     background: #f2ae2b;
     padding: 40px 0;
}
 .footer_strip i {
     color: #fff;
     font-size: 18px;
     padding-right: 5px;
}
 .footer_strip .sub-title {
     color: #252525;
     text-align: left;
}
 .footer_strip p a {
     color: #003366;
     font-family: 'Poppins', sans-serif;
     font-size: 15px;
     font-weight: 400;
     text-decoration: underline;
}
 .footer_strip p {
     margin-bottom: 0;
     color: #252525;
     font-family: 'Poppins', sans-serif;
     font-size: 15px;
     font-weight: 400;
     line-height: 24px;
}
 .footer_strip ul li a i {
     color: #fff3df;
     font-size: 24px;
}
 .footer_strip ul {
     padding-left: 0;
     padding-top: 5px;
}
 .footer_strip ul li {
     list-style: none;
     float: left;
     padding-right: 15px;
}
 .footer_strip ul li:hover a {
     text-decoration: none;
}
.footer_strip ul li:hover i {
     color: #f2ae2b;
}
 .footer_strip ul li:last-child {
     padding-right: 0;
}
 .footer_strip li a{
     text-decoration:none 
}
 .footer_strip li a:focus{
     outline:none;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 11 - FOOTER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .footer_01 {
     background: #f5f7fa;
     padding-top:47px;
     padding-bottom:0px;
}
 .footer_top .sub-title {
     color: #252525;
     text-align: left;
     margin-bottom: 20px;
}
 .footer_top .instagram_images ul {
     padding-left: 0;
}
 .footer_top .instagram_images ul li {
     list-style: none;
     float: left;
     padding-right: 7px;
     padding-bottom: 7px;
}
 .footer_top h5 {
     font-family: 'Poppins', sans-serif;
     color: #003366;
     font-size: 16px;
     font-weight: 600;
     margin-top: 0;
     margin-bottom: 0;
}
 .footer_top h6 {
     color: #323232;
     font-size: 13px;
     font-family: 'Poppins', sans-serif;
     text-transform: uppercase;
     font-weight: 400;
     margin-top: 5px;
}
 .recent_posts{
     margin-bottom: 15px;
}
 .recent_posts:last-child{
     margin-bottom:0;
}
 .recent_posts .text{
     padding-left:8px;
}
 .footer_top .simple-text p {
     text-align: left;
     line-height: 20px;
     margin-bottom: 10px;
}
 .footer_top a {
     font-family: 'Poppins', sans-serif;
     color: #003366;
     font-size: 14px;
     font-weight: 600;
     margin-top: 0;
}
 .subscribe_now{
     position:relative;
}
 .subscribe_now input{
     width:100%;
     font-size:14px;
     color:#707d99;
     font-family: 'Poppins', sans-serif;
     height:45px;
     line-height:45px;
     border:2px solid #fff;
     border-radius:25px;
     padding-left:20px;
     outline:none;
}
 .subscribe_now input::placeholder{
     color:#707d99;
}
 .subscribe_now button{
     position: absolute;
     right: 0;
     top: 0;
     height:41px;
     line-height:41px;
     width:127px;
     border-radius: 25px;
     border: 0;
     color:#ffffff;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
     font-size:15px;
     background:#f2ae2b;
     outline:none;
     margin: 2px;
}
 .popular_tags {
     margin-top:40px;
}
 .popular_tags ul{
     padding-left:0;
}
 .popular_tags ul li{
     list-style:none;
     float:left;
     background:#ffffff;
     margin-right:15px;
     padding:8px 12px;
     margin-bottom:15px;
     border-radius:3px;
}
 .popular_tags ul li a{
     color: #898989;
     font-size: 14px;
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     text-decoration: none;
}
 .footer_bottom{
     background: #003366;
     clear: both;
     padding: 30px;
     border-radius: 5px;
     margin-top: 30px;
}
 .footer_bottom p{
     margin-bottom:0;
     color:#ffffff;
     font-size:13px;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
}
 .footer_bottom .links ul{
     padding-left:0;
     margin: 0;
}
 .footer_bottom .links ul li{
     float:left;
     list-style:none;
}
 .footer_bottom .links{
     float:right;
}
 .footer_bottom .links ul li a{
     color:#ffffff;
     font-size:13px;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
     text-transform:none;
     border-right:1px solid #ffffff;
     padding-right:10px;
     padding-left:10px;
}
 .footer_bottom .links ul li.last a{
     border-right:0;
     padding-right:0px;
}
 .footer_bottom .copyright p span a{
     color:#f2ae2b;
	 text-decoration:none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10 & IE11 CSS styles go here */
    iframe[name="__cmpLocator"] {
        display: none;
    }
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 12- HOVER EFFECTS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .latest_work_01 .item:hover .overlay {
     opacity: 1;
     visibility: visible;
}
 .latest_work_01 .item .overlay {
     background: rgba(0, 0, 0, 0.55);
     display: block;
     height: 100%;
     width: 100%;
     color: #fff;
     text-align: center;
     position: absolute;
     visibility: hidden;
     opacity: 0;
     -webkit-transition: .25s;
     -moz-transition: .25s;
     -o-transition: .25s;
     -ms-transition: .25s;
     transition: .25s;
     top: 0;
}
 .latest_work_01 .item .overlay .inner {
     position: relative;
     top: 50%;
     -webkit-transform: translate(0,-50%);
     -moz-transform: translate(0,-50%);
     transform: translate(0,-50%);
}
 .latest_work_01 h5 {
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     font-size: 18px;
     line-height: 26px;
     margin-bottom: 10px;
     letter-spacing: .88px;
}
 .latest_work_01 .item .overlay a {
     color: #f2ae2b;
     font-size: 18px;
     margin: 0 7px;
     text-decoration:none;
}
/* image hover effect */
 .image-zoom-on-hover {
     overflow: hidden;
}
 .image-opacity-on-hover img, .image-zoom-on-hover img{
     transition: opacity 300ms ease-out, transform 300ms ease-out, filter 300ms ease-out;
     -moz-transition: opacity 300ms ease-out, -moz-transform 300ms ease-out, -moz-filter 300ms ease-out;
     -o-transition: opacity 300ms ease-out, -o-transform 300ms ease-out, -o-filter 300ms ease-out;
     -webkit-transition: opacity 300ms ease-out, -webkit-transform 300ms ease-out, -webkit-filter 300ms ease-out;
}
 .image-zoom-on-hover:hover img {
     -webkit-transform: scale(1.1);
     transform: scale(1.1);
}
 .image-opacity-on-hover:hover img {
     opacity: 0.50;
     filter: alpha(opacity=50);
}
 .image-opacity-on-hover {
     background: #003366;
}
/* about us page css */
 .banner{
    background-image: url(http://via.placeholder.com/1920x191);
     background-repeat:no-repeat;
     background-size:cover;
     padding:80px 0;
     position:relative;
	 background-position:center;
}
 .banner:before{
     position:absolute;
     top:0;
     bottom:0;
     left:0;
     right:0;
     content:'';
     background:#003366;
     opacity:0.75;
}
 .banner .tt-title{
     color:#fff;
     text-align:center;
     position:relative;
}
 .breadcrumb {
     background: #fff;
     position: relative;
     padding: 20px 0;
     vertical-align: middle;
     text-align: center;
     margin-top: 3px;
     margin-bottom: 0;
     box-shadow: 0 8px 43px rgba(0, 0, 0, 0.12);
     width:555px;
     margin:0 auto;
     border-radius:3px;
     margin-top:-30px;
}
 .breadcrumb ul {
     display: inline-block;
     padding-left: 0;
     margin-bottom: 0;
}
 .breadcrumb ul li {
     list-style: none;
     float: left;
     padding-right: 10px;
     color: #ffffff;
     background: 0;
}
 .breadcrumb ul li:last-child{
     padding-right:0;
}
 .breadcrumb ul li.active a {
     font-size: 14px;
     color: #898989;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
}
 .breadcrumb .icofont-double-right{
     color: #898989;
}
 .breadcrumb ul li a{
     font-size: 14px;
     color: #003366;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
}
 .aboutus{
     padding-top: 100px;
}
/* our_spacility section css */
 .our_spacility .content{
     margin-bottom:60px;
     margin-top:50px;
}
 .our_spacility .content:last-child{
     margin-bottom:0px;
}
 .our_spacility .img_2 img{
     position: absolute;
     z-index: 2;
     margin-left: 0;
     margin-top: 65px;
     border-top-right-radius: 30px;
     border-bottom-right-radius: 30px;
     width: 41.5%;
     height: 487px;
     object-fit: cover;
}
 .our_spacility .padding_0{
     padding-right:0;
}
 .our_spacility .our_special{
     padding-right:0;
}
 .our_spacility .our_special .icofont{
     font-size:36px;
     color: #003366;
     position:absolute;
     padding-left: 0;
     padding-right:30px;
}
 .our_spacility .our_special_1{
     border:1px solid #d6d7da;
     background-color:#f5f7fa;
     border-top-left-radius:7px;
     border-bottom-left-radius:7px;
     border-right:none;
     padding-left:0;
     float:right;
     padding-top:20px;
     padding-bottom:110px;
}
 .our_spacility .our_special_1 p{
     font-size:15px;
     font-weight:400;
     color:#898989;
     padding-left: 60px;
     padding-top: 5px;
     width: 700px;
     font-family: 'Poppins', sans-serif;
     line-height: 24px;
     margin-bottom: 0;
}
 .our_spacility .our_special_1 .border_bottom_50{
     border:.5px solid #403d44;
     width:50%;
     margin-top:50px;
     margin-left: -75px;
     width: 28%;
}
 .our_spacility .our_special_1 h2 {
     font-size: 16px;
     color: #0c0c0c;
     font-family: 'Poppins', sans-serif;
     padding-left: 60px;
     margin-top: 0px;
     font-weight: 500;
}
 .our_spacility .our_special_1 h2:hover {
     color:#f2ae2b;
}
 .our_spacility .our_special_1 .icofont:hover{
     color:#f2ae2b;
}
/* strip */
 .strip_01{
	 background: url(http://via.placeholder.com/2500x1684) no-repeat;
     background-size:cover;
     position:relative;
     background-attachment:fixed;
}
 .strip_01:before{
     position:absolute;
     top:0;
     bottom:0;
     left:0;
     right:0;
     content:'';
     background:#003366;
     opacity:0.75;
}
 .strip_01 .icofont {
     font-size: 60px;
     color: #f2ae2b;
     float: left;
     padding-top: 15px;
}
 .strip_01 h2 {
     margin-top: 0px;
     margin-bottom: 0px;
     font-size: 48px;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     color: #ffffff;
     padding-left: 85px;
}
 .strip_01 h3 {
     margin-top: 0px;
     margin-bottom: 0px;
     font-size: 18px;
     color: #f5f7fa;
     padding-top: 8px;
     font-family: 'Poppins', sans-serif;
     padding-left: 85px;
     font-weight: 400;
}
/*our_team section css */
 .our_team .our_team1 .simple-text{
     width:580px;
     margin:0 auto;
     margin-bottom:50px;
}
 .our_team h5{
     font-size: 15px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
     color: #898989;
     text-align:center;
}
 .our_team img{
     margin:0 auto;
}
 .our_team .sub-title{
     margin-bottom:0px;
}
 .our_team h5{
     margin-top:5px;
     margin-bottom:20px;
     color: #898989;
}
 .images_Specialistt {
     position: relative;
}
 .images_Specialistt:before {
     position: absolute;
     top: auto;
     left: 0;
     right: 0;
     bottom: 0;
     content: '';
     background: #f5f7fa;
     height: 194px;
     z-index: -1;
}
 .our_team .content{
     padding:50px 20px;
     padding-bottom:10px;
}
 .our_team .owl-carousel .owl-item img{
     width:auto;
}
 .our_team .owl-theme .owl-dots .owl-dot span {
     width: 16px;
     height: 16px;
     margin: 5px 7px;
     background: #fff ;
     border:2px solid #8d97ad;
     display: block;
     -webkit-backface-visibility: visible;
     transition: opacity .2s ease;
     border-radius: 30px;
     float: none;
     margin: 0 auto;
     padding-right: 10px;
}
 .our_team .owl-theme .owl-dots {
     transform: rotate(-90deg);
}
 .our_team .owl-theme .owl-dots .owl-dot {
     padding-top: 10px;
}
 .our_team .owl-theme .owl-dots .owl-dot.active span {
     background: #003366;
     padding:5px;
     border:2px solid #003366;
}
 .our_team{
     padding-bottom:70px;
}
/* free_home section css */
 .free_home{
     position: relative;
}
.free_home .button{
	text-align:center;
	padding-bottom: 70px;
}
.free_home .button8 span{
	position:relative
}
 .free_home .Free_Home_Enquiry{
     padding: 75px 0px 70px 20px;
}
 .free_home .form-classes input{
     height:44px;
     border-radius: 4px;
}
 .free_home .form-classes #sel1{
     height:44px;
     border-radius:2px;
}
 .free_home .form-classes label{
     color:#ffffff;
     font-size:16px;
     font-family: 'Poppins', sans-serif;
     font-weight:400;
     margin-top:25px;
     margin-bottom: 10px;
}
 .free_home .form-classes form{
     padding-top:50px;
     padding-left:0px;
}
 .free_home .tt-title {
     color: #ffffff;
     line-height: 36px;
}
 .free_home p{
     color:#cccccc;
     font-family: 'Poppins', sans-serif;
     font-size:15px;
     font-weight:500;
     line-height: 24px;
}
 .free_home .sub-title{
     color:#f2ae2b;
     text-align: left;
     margin-top: 35px;
     margin-bottom: 10px;
}
 .free_home h5{
     font-size:15px;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
     color:#ffffff;
     margin:0;
}
 .free_home .sim-button{
     margin-top:45px;
	 padding:0;
}
 .free_home .offset_10{
     background-color: #003366;
     width: 70%;
     margin-top: 96px;
     opacity: .9;
     position: absolute;
     top: 0;
     right: 0;
     margin-bottom: 96px;
}
 .free_home .image img{
     border:4px solid #fff;
     border-left:0;
     box-shadow: 0 8px 43px rgba(0, 0, 0, 0.1);
}
/* our_customer_saying section css */
 .our_customer_saying{
     background: url("http://via.placeholder.com/1920x584") no-repeat;
     background-size: cover;
}
 .our_customer_saying .simple-text{
     width:580px;
     margin:0 auto;
     margin-bottom:50px;
}
 .our_customer{
     background:#ffffff;
     box-shadow: 0 8px 43px rgba(0, 0, 0, 0.1);
     padding: 50px 50px 50px 25px;
}
 .our_customer p{
     float:left;
     font-size:15px;
     font-weight:400;
     color:#898989;
     font-family: 'Poppins', sans-serif;
     text-align:left;
     line-height:24px;
     margin-bottom:20px;
}
 .our_customer h2{
     float:left;
     font-size:15px;
     font-weight:600;
     color:#003366;
     font-family: 'Poppins', sans-serif;
     text-align:left;
     width: 100%;
     margin-top:0;
     margin-bottom: 8px;
}
 .our_customer .ico1 .icofont.icofont1{
     color: #f2ae2b;
     font-size: 16px;
}
 .our_customer .ico1 .icofont-star{
     color:#e4e4e4;
     font-size: 16px;
}
/* about_us_02 page css */
 .about_technologies{
	background: url(http://via.placeholder.com/1920x482) no-repeat;
	background-size: cover;
	margin-top: -35px;
	z-index: -1;
	position:relative;
}
 .about_technologies:before{
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     content: '';
     background: #f5f7fa;
     opacity:0.90;
}
 .about_technologies .technologies{
     position:relative;
     box-shadow:none;
}
 .about_technologies .technologies .simple-text p {
     color: #000000;
}
 .about_technologies .technologies i{
     color:#f2ae2a;
}
 .about_technologies .technologies .sub-title {
     margin-bottom: 10px;
}
 .about_technologies .technologies .single-sec {
     padding-top: 30px;
}
/* strip_02 section css */
 .strip_02{
     background:#fff;
}
 .strip_02 h2{
     color:#003366;
}
 .strip_02 h3{
     color:#898989;
}
 .strip_02:before{
     display:none;
}
/* why_choose_constructor css */
 .why_choose_constructor {
     background:#f5f7fa;
}
 .why_choose_constructor .simple-text{
     width:630px;
     margin:0 auto;
}
 .why_choose_constructor .facility .icon{
     height:80px;
     width:80px;
     border-radius:50%;
     background:#f2ae2b;
     padding:22px;
}
 .why_choose_constructor .facility .icon i{
     font-size:36px;
     color:#fff;
}
 .why_choose_constructor .facility .sub-title{
     color:#292929;
     text-align:left;
}
 .why_choose_constructor .facility p {
     color: #898989;
     font-size: 15px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
     line-height: 24px;
     margin-bottom: 30px;
}
 .why_choose_constructor .facility .border_bottom{
     width:63px;
     border-bottom:2px solid #f2ae2b;
     margin-bottom:6px;
}
 .why_choose_constructor .facility h5 a{
     font-family: 'Poppins', sans-serif;
     font-size:16px;
     font-weight:600;
     color:#003366;
     letter-spacing: 0.30px;
     text-decoration:none;
}
 .why_choose_constructor .facility h5{
     margin-top:0;
     margin-bottom:0;
}
 .why_choose_constructor .facility{
     margin-top:50px;
}
 .about_us_features_01 {
     padding-top:100px;
}
/* services page one css */
 .service_page_01{
     background:#fff;
     padding-top:60px;
     padding-bottom:100px;
     height:auto;
}
 .service_page_01 .single_service {
     margin-top: 40px;
}
 .service_page_01 .single_service .content .sim-button {
     margin-top: 35px;
}
 .service_page_01 .single_service .content{
     padding-bottom:60px;
}
/* Services_Key_Features */
 .Services_Key_Features{
     background-color:#f5f7fa;
}
 .Services_Key_Features .Key_Features .simple-text{
     width:630px;
     margin:0 auto;
     margin-bottom:40px;
}
 .Services_Key_Features .features_01{
     margin-top:25px;
}
 .Services_Key_Features .features_01 .icon{
     text-align:center;
     margin-top: 15px;
}
 .features_01{
     border:1px solid #dfdfdf;
     margin-top:60px;
     background-color:#ffffff;
     width:100%;
     padding: 35px 15px;
}
 .features_01 .icofont{
     font-size:72px;
     color:#8d97ad;
     opacity:0.4;
}
 .features_01 h3{
     font-size:18px;
     font-family: 'Poppins', sans-serif;
     font-weight:600;
     color:#292929;
     text-align:left;
     margin-top:0;
     margin-bottom:15px;
}
 .features_01 p{
     font-size:15px;
     font-family: 'Poppins', sans-serif;
     font-weight:400;
     color:#898989;
     text-align:left;
     margin-bottom:0;
     width:100%;
     line-height: 24px;
}
/* request_A_quote */
 .request_A_quote{
     padding-top:50px;
     padding-bottom:50px;
	 clear:both;
}
 .request_A_quote p{
     color:#fff;
     font-family: 'Poppins', sans-serif;
     font-weight: 400;
     font-size:18px;
     margin-bottom: 3px;
}
 .request_A_quote .h4{
     color:#ffffff;
     font-family: 'Poppins', sans-serif;
     font-weight: 700;
     font-size: 36px;
     margin-top:0;
     margin-bottom: 15px;
}
 .request_A_quote .button_request{
     float:right;
}
 .request_A_quote .button_request .sim-button{
     background:#ca4357;
     text-transform: uppercase;
     border-radius: 3px;
}
 .request_A_quote .button_request .button8::before{
     background:#e9d480;
     border-radius: 3px;
}
 .free_home_01{
     margin-top:100px;
}
/*Our_Pricing*/
 .Our_Pricing .pricing_Features .simple-text{
     width:630px;
     margin:0 auto;
}
 .Our_Pricing .pricing{
     background-color: #f5f7fa;
     border:4px solid #ffffff;
     margin-top:50px;
     box-shadow: 0px 8px 43px rgba(0, 0, 0, 0.1);
}
 .Our_Pricing .pricing ul{
     padding-left:0;
     list-style:none;
     margin:0;
}
 .Our_Pricing .pricing h5{
     color:#003366;
     font-size:18px;
     text-align:center;
     font-weight:600;
     margin-top:0;
     margin-bottom:0;
     padding-top:25px;
     padding-bottom:25px;
     font-family: 'Poppins', sans-serif;
}
 .Our_Pricing .pricing h4{
     background-color: #003366;
     color:#ffffff;
     font-size:48px;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
     text-align:center;
     word-wrap: break-word;
     padding-top:25px;
     padding-bottom:25px;
     margin-top:0;
     margin-bottom:0;
}
 .Our_Pricing .pricing h4 span{
     font-size:14px;
     font-weight:400;
}
 .Our_Pricing .pricing h4 .icofont{
     font-size:18px;
     font-weight:500;
     padding-top:-12px;
}
 .Our_Pricing .pricing h6{
     background-color: #f2ae2b;
     color:#ffffff;
     font-size:48px;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
     text-align:center;
     word-wrap: break-word;
     padding-top:25px;
     padding-bottom:25px;
     margin-top:0;
     margin-bottom:0;
}
 .Our_Pricing .pricing h6 span{
     font-size:14px;
     font-weight:400;
}
 .Our_Pricing .pricing h6 .icofont{
     font-size:18px;
     font-weight:500;
     padding-top:-12px;
}
 .Our_Pricing .pricing ul li{
     text-align:center;
     padding-top:20px;
     padding-bottom:20px;
     border-bottom:1px solid #e3e3e3;
     font-family: 'Poppins', sans-serif;
}
 .Our_Pricing .pricing ul li span{
     color:#262626;
     font-weight:600;
     font-size: 15px;
}
 .Our_Pricing .pricing ul li{
     color:#898989;
     font-weight:400;
     font-size: 15px;
}
 .Our_Pricing .pricing .sim-button1{
     margin:0 auto;
     text-transform:capitalize;
     font-size:15px;
     text-align:center;
     margin-top:25px;
     margin-bottom:27px;
     font-family: 'Poppins', sans-serif;
}
 .Our_Pricing .sim-button{
     margin:0 auto ;
     text-align:center;
     margin-top:25px;
     margin-bottom:27px;
}
 .yellowbtn{
     background:#f2ae2b;
}
 .yellowbtn.button8:before{
     background:#003366;
}
/* services page two css */
 .service_page_02{
     margin-top:100px;
     margin-bottom:100px;
}
 .service_page_02 h3{
     padding-top:95px;
     font-size:18px;
     color:#003366;
     font-family: 'Poppins', sans-serif;
     font-weight:600;
}
 .service_page_02 .con_serviecs1 .border_radius .icofont{
     font-size:48px;
     color:#ffffff;
     margin:0 auto;
}
 .service_page_02 .con_serviecs1 .border_radius{
     text-align:center;
}
 .service_page_02 .con_serviecs1 .border_radius{
     background-color: #f2ae2b;
     height: 88px;
     width: 88px;
     padding-top: 20px;
     border-radius: 50%;
}
 .service_page_02 .con_serviecs1{
     position: absolute;
     right: 75px;
     top: 36%;
}
 .service_page_02 .border_radius1{
     border: 1px solid #dfdfdf;
     box-shadow: 0px 8px 43px 0px rgba(0, 0, 0, 0.1);
     padding: 75px 0px 90px 0px;
     margin-left: 75px;
     width: 700px;
}
 .service_page_02 .Service_Sec .sub-title{
     text-align:left;
}
 .service_page_02 .Service_Sec .simple-text p{
     text-align:left;
     width: 330px;
}
 .service_page_02 .Service_Sec .content{
     padding-top: 46px;
     padding-bottom: 46px;
     margin-left: -50px;
}
 .service_page_02 .Service_Sec img{
     border: 3px solid #fff;
     margin-left: -75px;
     position: absolute;
     box-shadow: 0px 8px 43px 0px rgba(0, 0, 0, 0.1);
}
 .service_page_02 .col-md-offset-3 {
     margin-left: 30%;
}
 .service_page_02 .border_radius1.second{
     margin-top:75px;
     margin-bottom:75px;
}
 .service_page_02 .border_radius1.second .con_serviecs1{
     margin-top: 25px;
}
/* project_2columns */
 .project_2columns .sub-title {
     margin-bottom: 5px;
     margin-top:20px;
}
 .project_2columns .portfolio-item{
     padding:20px;
     box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.1);
     border:1px solid #dfdfdf;
     margin-top:40px;
}
 .project_2columns ul.portfolio-sorting {
     margin-bottom: 0;
}
 .project_2columns .list-unstyled{
     margin-bottom:0;
}
 .project_2columns .sim-button{
     margin-top:75px;
}
/* project_3columns */
 .project_3columns{
     padding-bottom:100px;
}
 .project_3columns ul.portfolio-sorting{
     margin-bottom:0;
}
 .project_3columns .portfolio-item{
     margin-top:40px;
     position: relative;
}
/* */
 .project_masonary{
     padding-bottom:0px;
}
 .project_masonary ul.portfolio-sorting{
     margin-bottom:10px;
}
 .project_masonary .portfolio-item{
     margin-top:30px;
     position: relative;
}
 .project_masonary .sim-button{
     margin-top:75px;
     clear:both;
}
/* project_details page css */
 .project_details .sub-title{
     text-align:left;
}
 .project_details .content .simple-text p{
     text-align:left;
     margin-bottom: 20px;
}
 .project_details ul{
     padding-left:0;
}
 .project_details ul li span{
     font-family: 'Poppins', sans-serif;
     color:#000000;
     font-weight:600;
     position:absolute;
     left:15px;
}
 .project_details img{
     padding-right:15px;
}
 .project_details ul li {
     list-style:none;
     font-weight:400;
     color:#666666;
     font-family: 'Poppins', sans-serif;
     padding-left: 140px;
     font-size:15px;
     line-height:46px;
}
 .project_details .row0{
     margin-top:40px;
}
 .project_details .row0 .simple-text p{
     margin-bottom:20px;
     text-align:left;
}
 .project_details .row0 .simple-text p:last-child{
     margin-bottom:0;
}
 .project_details .row0 h5{
     font-size: 15px;
     font-family: 'Poppins', sans-serif;
     font-weight: 400;
     color: #ffffff;
     background-color: #f2ae2b;
     padding: 15px 20px 15px 20px;
     border-radius: 3px;
     margin-top: 0;
     margin-bottom: 10px;
}
 .project_details .row0 .text .sub-title{
     margin-bottom:20px;
}
 .related_projects{
     background:#f5f7fa;
}
 .related_projects .simple-text{
     width:630px;
     margin:0 auto;
     margin-bottom:50px;
}
 .related_projects .latest_work_01{
     padding-bottom:0;
}
 .related_projects .owl-carousel .owl-nav.disabled {
     display:block;
}
 .related_projects .owl-carousel .owl-nav .owl-next, .related_projects .owl-carousel .owl-nav .owl-prev{
     width:74px;
     height:74px;
     border-radius:50%;
     background:#fff;
     color: #003366;
}
 .related_projects .owl-carousel .owl-nav .owl-next:hover, .related_projects .owl-carousel .owl-nav .owl-prev:hover{
     background:#f2c21a;
     color:#fff;
}
 .related_projects .owl-carousel .owl-nav i{
     font-size: 36px;
     padding: 20px;
     line-height: 72px;
     padding-left: 18px;
}
 .related_projects .owl-carousel{
     position:relative;
}
 .related_projects .owl-carousel .owl-nav .owl-prev{
     position: absolute;
     left: 30px;
     z-index: 1;
     top: 35%;
}
 .related_projects .owl-carousel .owl-nav .owl-next{
     position: absolute;
     right: 30px;
     z-index: 1;
     top: 35%;
}
/* project details 2 */
 .project_images{
     margin-top:-40px;
}
 .project_details_02 .content{
     border: 1px solid #dfdfdf;
     padding: 40px;
     padding-bottom:30px;
     margin-top: -170px;
     z-index: 1;
     background: #fff;
     box-shadow: 0px 8px 43px rgba(0, 0, 0, 0.1);
}
 .project_details_02 ul li span {
     font-family: 'Poppins', sans-serif;
     color: #000000;
     font-weight: 600;
     position: absolute;
     left: 55px;
}
 .project_details_02 ul {
     padding-left: 10px;
     margin-bottom: 0;
}
 .project_details_02 img {
     padding-right: initial;
     border:3px solid #fff;
     box-shadow: 0px 8px 43px rgba(0, 0, 0, 0.1);
}
 .project_details_02 .right_section{
     padding-top:80px;
}
 .project_details_02{
     padding-bottom:100px;
}
 .project_details_02 .item{
     position:relative;
}
 .project_details_02 .item:hover .overlay {
     opacity: 1;
     visibility: visible;
}
 .project_details_02 .item .overlay {
     background: rgba(0, 0, 0, 0.55);
     display: block;
     height: 100%;
     width: 100%;
     color: #fff;
     text-align: center;
     position: absolute;
     visibility: hidden;
     opacity: 0;
     -webkit-transition: .25s;
     -moz-transition: .25s;
     -o-transition: .25s;
     -ms-transition: .25s;
     transition: .25s;
     top: 0;
}
 .project_details_02 .item .overlay .inner {
     position: relative;
     top: 50%;
     -webkit-transform: translate(0,-50%);
     -moz-transform: translate(0,-50%);
     transform: translate(0,-50%);
}
 .project_details_02 .item .overlay a {
     color: #fff;
     font-size: 30px;
     margin: 0 7px;
     text-decoration:none;
}
/* map css */
 .google-maps {
     position: relative;
     padding-bottom: 28%;
     height: 0;
     overflow: hidden;
}
 .google-maps iframe {
     position: absolute;
     top: 0;
     left: 0;
     width: 100% ;
     height: 100% ;
     border:0;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 13 - Contact Us */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .contact_us .icon_classes{
     border:1px solid #dfdfdf;
     background-color:#f5f7fa;
     text-align:center;
     padding:45px;
}
 .contact_us .icon_classes h5{
     font-size: 18px;
     color: #252525;
     font-weight: 600;
     font-family: 'Poppins', sans-serif;
     width: 100%;
     margin-top: 0;
}
 .contact_us .icon_classes .icofont {
     color: #f2ae2b;
     padding-right: 10px;
     font-size: 18px;
}
 .contact_us .icon_classes p{
     color:#252525;
     font-weight:500;
     font-family: 'Poppins', sans-serif;
     margin-bottom:0;
     font-size:15px;
     line-height: 24px;
}
 .contact_us .icon_classes a{
     color:#003366;
     font-weight:500;
     font-family: 'Poppins', sans-serif;
     margin-bottom:0;
     text-decoration:underline;
     cursor:pointer;
}
.contact_form{
     padding-bottom:100px;
}
 .contact_form .simple-text p{
     margin:0 auto;
     width:590px;
     margin-bottom: 50px;
}
 .contact_us2_form input{
     border:2px solid #eeeff0;
     height:44px;
     background-color:#fcfcfc;
     box-shadow:none;
     margin-bottom: 30px;
}
 .contact_us2_form label{
     color: #898989;
     font-family: 'Poppins', sans-serif;
     font-weight: 400;
     font-size: 16px;
     font-weight: 500;
}
 .contact_us2_form textarea{
     background-color:#fcfcfc;
     border:2px solid #eeeff0;
     box-shadow:none;
     height: 164px;
}
 .contact_us2_form textarea:focus{
     outline: none;
     border:2px solid #eeeff0;
     box-shadow: none;
}
 .contact_form .form-group{
     margin-bottom:0px;
}
 .contact_form .top_bottom label{
     margin-bottom:10px;
}
 .contact_form .sim-button{
     margin-top:30px;
     border: 0;
     padding:0;
}
 .contact_form .button8 span{
     position:relative;
}
 .contact_form .form_btn{
     text-align:center;
}
 .contact_quote{
	 background: url(http://via.placeholder.com/1920x1674) no-repeat;
     background-size:cover;
     position:relative;
     background-attachment:fixed;
}
 .contact_quote:before {
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     content: '';
     background: #003366;
     opacity: 0.75;
}
 .contact_quote p {
     color: #fff;
}
 .contact_quote .button_request .sim-button{
     background:none;
}
 .contact_quote .button_request .button8::before {
     background: #f2ae2b;
}
/* home page 2 */
 .header2 .tt-header{
     background: #fff;
}
 @media(min-width:992px){
     .header2 .tt-header .main-nav>ul>li>a{
         color: #000;
    }
     .header2 .tt-header .main-nav>ul>li.active>a{
         color: #000;
    }
     .header2 .tt-header .top-inner {
         position: relative;
         float: left;
         padding: 24px 0;
         padding-right: 35px;
    }
}
 .header2 .header_top_02{
    background: #354a50;
    border-top: 0;
    padding-bottom: 0;
}
 .header_top_02 ul li, .header_top_02 ul li a{
     list-style:none;
     float:left;
     font-family: 'Poppins', sans-serif;
     font-size: 13px;
     font-weight: 500;
     color:#fff;
     padding-right:22px;
}
 .header_top_02 ul{
     padding-left:0;
     margin-bottom:0;
}
 .header_top_02 ul li i{
     padding-right:8px;
     color:#003366;
}
 .header_top_02{
     padding:15px 0;
}
 .header_top_02 p{
     font-family: 'Poppins', sans-serif;
     font-size: 13px;
     font-weight: 500;
     color:#fff;
     text-align:right;
}
 .header_top_02 p span{
     padding-left:13px;
}
 .hmslider2 .slider_content {
     width: 60%;
     float: none;
     margin: 0 auto;
     background: rgba(0,0,0,0.50);
     padding-bottom: 120px;
     padding-top: 60px;
     margin-top: 120px;
     margin-bottom: 120px;
}
 .hmslider2 .tt-mslide-title p{
     width:70%;
     margin:0 auto;
     padding-bottom:20px;
}
 .swiper-button-prev i, .swiper-button-next i{
     font-size:36px;
     color:#fff;
     display:inline-block;
     vertical-align:middle;
     padding:25px;
}
 .swiper-button-prev, .swiper-button-next {
     position: absolute;
     top: 50%;
     width: 84px;
     height: 84px;
     margin-top: -22px;
     z-index: 10;
     cursor: pointer;
     -moz-background-size: 27px 44px;
     -webkit-background-size: 27px 44px;
     background-size: 27px 44px;
     background-position: center;
     background-repeat: no-repeat;
     background: #f2ae2b;
     border-radius: 50%;
}
 .swiper-button-prev:hover, .swiper-button-next:hover{
     background: #fff;
}
 .swiper-button-prev:hover i, .swiper-button-next:hover i{
     color:#003366;
}
 .welcome_section h4{
     font-family: 'Poppins', sans-serif;
     font-size:20px;
     color:#0c0c0c;
     margin-top: 0;
}
 .welcome_section h2{
     font-family: 'Poppins', sans-serif;
     font-size:36px;
     font-weight:400;
     color:#003366;
     margin-top: 15px;
     margin-bottom: 25px;
}
 .welcome_section h2 span{
     color:#f2ae2b;
}
 .welcome_section .simple-text p{
     text-align:left;
     padding-bottom:15px;
}
 .welcome_section .sim-button{
     width:129px;
     height:43px;
     line-height:43px;
     border-radius:25px;
     margin: 0;
     float:left;
}
 .welcome_section .button8:hover::before {
     opacity: 1;
     width: 100%;
     border-radius: 25px;
}
 .welcome_section .sim-button.nextbtn{
     width:176px;
     height:43px;
     margin-left:10px;
     background:#f2ae2b;
}
 .welcome_section .sim-button.nextbtn:hover .button8{
     background:#003366;
}
 .welcome_section .buttons{
     margin-top:25px;
}
 .welcome_section .image {
     padding-top: 125px;
     padding-bottom: 0;
     float:right;
}
 .welcome_section .image img{
     box-shadow: -6px 10px 20px rgba(0, 0, 0, 0.1);
}
 .welcome_section .nextbtn.button8:hover::before{
     background:#003366;
}
 .about_us_features_02{
     padding-top:0;
}
 .power_plant{
	 background: url(http://via.placeholder.com/1920x512) no-repeat;
     background-size:cover;
     position:relative;
     padding:130px 0;
     background-position: right;
}
 .power_plant p{
     font-family: 'Poppins', sans-serif;
     font-size:16px;
     color:#898989;
     font-weight:400;
}
 .power_plant h3{
     font-family: 'Poppins', sans-serif;
     font-weight:600;
     font-size:24px;
     color:#003366;
     margin-top: 10px;
}
 .power_plant .sim-button{
     border:2px solid #f2ae2b;
     background:transparent;
     margin-top:30px;
     border-radius:3px;
}
 .power_plant .sim-button span{
     color:#f2ae2b;
}
 .power_plant .button8:hover:before{
     background:#003366;
}
 .power_plant .button8:hover{
     border:2px solid #003366;
     border-radius:0px;
}
 .power_plant .content{
     width:30%;
     float:right;
     text-align:center;
}
 .power_plant .button8:hover span{
     color:#fff;
}
 .free_consulting:before{
     position:absolute;
     left:0;
     right:0;
     top:0;
     bottom:0;
     background:#f5f7fa;
     content:'';
     width: 57.8%;
}
 .free_consulting .form-classes label{
     color:#898989 
}
 .free_consulting .Free_Home_Enquiry {
     padding: 0;
}
 .free_consulting .tt-title{
     color: #003366;
}
 .free_consulting p {
     width: 425px;
     color: #898989;
}
 .free_consulting .form-classes form {
     padding-top: 0;
     padding-left: 0px;
}
 .free_consulting .strip_01 {
     background:#003366;
     position: absolute;
     right: 0;
     bottom: 90px;
     width: 42.3%;
     padding:50px 0;
}
 .free_consulting .strip_01 h2{
     padding-left:15px;
}
 .free_consulting .strip_01 h3{
     padding-left:15px;
}
 .free_consulting .Free_Home_Enquiry i{
     font-size: 72px;
     color: #ffca6c;
}
 .free_consulting .sub-title{
     color:#fff;
     margin-top: 14px;
}
 .free_consulting .call_us {
     background: #f2ae2b;
     padding: 40px 25px;
     margin-top: 50px;
     margin-left: 73px;
}
 .free_consulting .button8 span{
     position:relative;
}
 .free_consulting .sim-button {
     margin-top: 45px;
     padding: 0;
     outline: none;
}
 .free_consulting .button{
     text-align:center;
	 padding-bottom:0;
}
 .free_consulting h5{
     color: #003366;
     font-weight:600;
}
 .free_consulting .form-classes{
     padding:50px 0;
     width: 55%;
}
 .free_consulting .call_us_01{
     width:44%;
}
 .client_carousel_02{
     background:#fff;
     padding-bottom: 200px;
}
 .footer_main{
     background:#003366;
     padding:30px;
     border-radius:3px;
     margin-top:-100px;
}
 .footer_strip_02 ul{
     display:inline-block;
     margin-bottom: 0;
}
 .footer_strip_02 .footer_main p{
     color: #c6c6c6;
     text-align:left;
     padding: 20px 0;
}
 .footer_strip_02{
     background:#f5f7fa;
}
 .footer_strip_02 i{
     color:#f2ae2b;
}
 .footer_one{
     padding:40px 0;
}
 .footer_strip_02{
     padding:0;
}
/* */
 .latest_blog_02{
	 background: url(http://via.placeholder.com/1920x3744) no-repeat;
     background-size: cover;
     position: relative;
     clear: both;
     background-attachment: fixed;
     z-index: 111;
	 background-position: center;
}
 .latest_blog_02:before {
     background: #003366;
     opacity: 0.75;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     content: '';
}
 .latest_blog_02 .content_01{
     position:relative;
}
 .latest_blog_02 .content{
     background:#fff;
}
 .latest_blog_02 .tt-title{
     color:#fff;
}
 .latest_blog_01 .simple-text p{
     color: #898989;
}
.latest_blog_02 .simple-text p{
     color: #fff;
}

/* homepage 3 css */
 
@media(min-width:992px){
	.header3 .tt-header {
		background:transparent;
		border-bottom: 1px solid #80a3bf;
	}
	.header3 .tt-header {
		position: absolute;
		top: 0;
	}
	.header3 .header2.stick{
		position:fixed;
		top:0;
		background: #003366;
	}
	.header3 .tt-header .top-inner {
		padding: 42px 0;
		padding-bottom: 40px;
		padding-right: 35px;
	}
	.header3 .tt-header .toggle-block {
		padding-top: 35px;
	}
}
.header3{
	position:relative;
}
.header_top_03{
	position:relative;
}

.header_top_03 p{
	font-family: 'Poppins', sans-serif;
	font-size:15px;
	font-weight:400;
	color: #f2ae2b;
	text-align:right;
}
.header_top_03 p span{
	font-size:18px;
	font-weight:600;
	padding-left:13px;
	color:#fff;
}
.header_top_03 .pull-right{
	position: absolute;
	top: 30px;
    z-index: 2;
    right: 0;
}


/* slider css */

.hmslider3 .tt-mslide-entry{
	height: 694px;
    padding-bottom: 0;
}
.hmslider3 .tt-mslide-entry:before{
	background:#003366;
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	top:0;
	content:'';
	opacity: 0.60;
}
.hmslider3 .slider_content{
	background: none;
    margin: 0;
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: left;
}
.hmslider3 .tt-mslide-title h3{
	text-align:left;
	
}
.hmslider3 .tt-mslide-title p{
	width: 70%;
    margin: 0;
    padding-bottom: 20px;
    text-align: left;
}
.hmslider3 .whitebtn{
	margin:0;
}

.hmslider3  .swiper-button-prev, .hmslider3  .swiper-container-rtl .swiper-button-next {
       right: 23%;
    left: auto;
}
.hmslider3 .swiper-button-next, .hmslider3 .swiper-container-rtl .swiper-button-prev {
	right: 18%;
    left: auto;
}

/* *****************************/

.welcome_section_02 .image_sec{ 
	width: 50%;
    position: absolute;
    right: 0;
	top:-100px;
	z-index:1;
}
.welcome_section_02{
	position:relative;
	padding-bottom: 130px;
}
.imgheight{
	height:300px;
	object-fit:cover;
	object-position:center right;
}
.imgheight1{
	height:250px;
	object-fit:cover;
	object-position:center;
}

/*featured_project_01*/
 .featured_project_01{
     position: relative;
	 padding-top:240px
}
 .featured_project_01 img{
     vertical-align:middle;
     width:100%;
}
 .featured_project_01 .col-md-4{
	position: absolute;
    right:0px;
    top: 80px;
}
 .featured_project_01 .featured_project{
     text-align:center;
     border:1px solid #dfdfdf;
     background-color:#ffffff;
	 padding: 40px 30px;
     box-shadow: 0px 8px 43px 0px rgba(0, 0, 0, 0.1);
}
.featured_project_01 p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #898989;
    font-weight: 400;
}

.featured_project_01 h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #003366;
    margin-top: 10px;
}


/*button*/
.featured_project_01 .sim-button {
    border: 2px solid #f2ae2b;
    background: transparent;
    margin-top: 30px;
    border-radius: 3px;
}
.featured_project_01 .button8:hover {
    border: 2px solid #003366;
    border-radius: 0px;
}


.featured_project_01 .sim-button span{
	color:#f2ae2b;
}
.featured_project_01 .button8:hover:before{
	background:#003366;
}

.featured_project_01 .content{
	width:30%;
	float:right;
	text-align:center;
}
.featured_project_01 .button8:hover span{
	color:#fff;
}

/*------------------------------ vertical bootstrap slider----------------------------*/

.mywork .carousel-inner> .item.next ,  .carousel-inner > .item.active.right{ transform: translate3d(0, 100%, 0); -webkit-transform: translate3d(0, 100%, 0); -ms-transform: translate3d(0, 100%, 0); -moz-transform: translate3d(0, 100%, 0); -o-transform: translate3d(0, 100%, 0);  top: 0;}
.mywork .carousel-inner > .item.prev ,.carousel-inner > .item.active.left{ transform: translate3d(0,-100%, 0); -webkit-transform: translate3d(0,-100%, 0);  -moz-transform: translate3d(0,-100%, 0);-ms-transform: translate3d(0,-100%, 0); -o-transform: translate3d(0,-100%, 0); top: 0;}
.mywork .carousel-inner > .item.next.left , .carousel-inner > .item.prev.right , .carousel-inner > .item.active{transform:translate3d(0,0,0); -webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);; -moz-transform:translate3d(0,0,0); -o-transform:translate3d(0,0,0); top:0;}

/*------------------------------- vertical carousel indicators ------------------------------*/
.mywork .carousel-indicators{
position:absolute;
top:0;
bottom:0;
margin:auto;
height:20px;
right:auto;
left: 20px;
width:auto;
z-index: 1;
}


.mywork .carousel-indicators li{
	display:block;
	margin-bottom:5px; 
	border:2px solid #ffffff; 
	width: 18px;
    height: 18px;
}
.mywork .carousel-indicators li.active{
	margin-bottom:5px; 
	background:transparent;
	border:2px solid #f2ae2b;
	width: 18px;
    height: 18px;
	margin-bottom:5px; 
	    margin-left: 1px;
}

#myCarousel{
	margin-left:0px;
}
/* */

.free_home_02 .Free_Home_Enquiry{
	background:#003366;
	padding: 45px 30px 45px 30px;
}
.free_home_02 .tt-title {
    font-weight: 500;
}

.free_home_02 .form-classes label{
	color:#898989;
}
.free_home_02 .button{
	text-align:center;
}
.free_home_02 .sim-button{
	padding:0;
	outline:none;
}
.free_home_02 .button8 span{
	position:relative;
}
.free_home_02 .form-classes form {
    padding-top: 5px;
    padding-left: 0px;
}
/* -------------------------------------- */

.why_choose_constructor_01{
	background: url(http://via.placeholder.com/1920x5687) no-repeat;
    background-size: cover;
    position: relative;
    clear: both;
    background-attachment: fixed;
    z-index: 111;
	background-position:center;
}
.why_choose_constructor_01:before{
	background: #003366;
    opacity: 0.75;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
}
.why_choose_constructor_01 .content{
	position:relative;
}
.why_choose_constructor_01 .tt-title{
	color:#fff;
}
.why_choose_constructor_01 .simple-text p{
	color:#fff;
}
.why_choose_constructor_01 .facility .sub-title {
    color: #f7f7f7;
    text-align: left;
}
.why_choose_constructor_01 .facility p{
	color:#fff;
}
.why_choose_constructor_01 .facility h5 a{
	color:#fff;
}
.why_choose_constructor .facility .icon i{
	color:#003366;
}

.latest_blog_03 .simple-text p{
	color:#898989;
}
.our_customer_saying_01{
	background: url(http://via.placeholder.com/1920x584) no-repeat;
	background-size:cover;
	background-position:center;
}
.services_03{
	background: url(http://via.placeholder.com/1920x545) no-repeat;
	background-size:cover;
	background-position: center;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* - BLOG-PAGE */
/*-------------------------------------------------------------------------------------------------------------------------------*/

.blog_grid .single_post{
	margin-bottom:80px;
}


/*Pagination*/


.pagination_1{
	clear: both;
    text-align: center;
}
.pagination_1 .pagination{
	margin-top:0;
	margin-bottom:0;
}
.pagination li a{
	margin-right: 8px;
	border-radius:4px;
	font-size:16px;
	font-weight:600;
	border:none;
	background-color:#f5f7fa;
	color:#4c4c4c;
	width:35px;
	padding: 8px 5px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
	color:#fff;
	background:#003366;
}
.pagination .icofont{
	height:35px;
	width:35px;
	color:#ffffff;
}
.pagination>li:last-child>a{
	background:#f2ae2b;
}
.pagination>li:first-child>a{
	background:#f2ae2b;
}
/**/


.blog_posts .form-group input[type="text"] {
    position: relative;
    width: 100%;
    background: #f5f7fa;
    border: 0;
    height: 41px;
	border-radius:2px;
	padding-left:20px;
}
.blog_posts .form-group button[type="submit"] {
    position: absolute;
    right: 30px;
    padding: 37px 82px;
    top: 0;
    right: 0;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
    border: 0;
    background: transparent;
}
.blog_posts .form-group button[type="submit"]:focus{
	border:0;
	outline: none;
}
.blog_posts .form-group button{
	font-size:14px;
	color:#5c5c5c;
}
.blog_posts label{
	color: #5c5c5c;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
}
/*Categories*/
.Categories{
	margin-top:40px;
	margin-bottom:40px;
}
.blog_posts h4{
	color: #5c5c5c;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
}
.Categories ul{
	margin-bottom:0;
	padding-left:0;
}
.Categories ul li{
	list-style-type: none;
    color: #003366;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
}
.Categories ul li a{
	color: #003366;
	text-decoration:none;
}
.Categories ul li a:hover{
	color:#f2ae2b;
}
.Categories ul li:first-child{
	padding-top:0;
}
.Categories ul li:last-child{
	border-bottom:none;
	padding-bottom:0;
}
.Categories ul li .icofont{
	color:#f2ae2b;
}
/*Recent Post*/
.Recent_Post{
	margin-bottom:20px;
}
.Recent_Post .image{

	width:78px;
	float:left;
	display:inline-block;
}
.Recent_Post1{
	padding-top: 5px;
    padding-left: 15px;
    width: auto;
    display: inline-block;
    float: left;
}

.Recent_Post1 h5{
	margin-top:0;
	margin-bottom:0;
	line-height:20px;
	color:#2c2034;
	font-family: 'Poppins', sans-serif;
	font-size:15px;
	font-weight:600;
}
.Recent_Post1 h5 a{
	color:#2c2034;
}
.Recent_Post1 h5 a:hover{
	color:#f2ae2b;
}

.Recent_Post1 p{
	margin-bottom:0;
	padding-top:8px;
	color:#898989;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight:400;
}
/*From_Instagram*/

.From_Instagram h4{
	margin-bottom:20px;
}
.From_Instagram img {
    border: 02;
    box-shadow: none;
    float: left;
    padding-right: 20px;
    padding-bottom: 15px;
	width: auto;
}

.space_15{
	margin-top:15px;
}
.From_Instagram .col-md-6{
	padding-right:10px;
	padding-left:10px;
}
/*Construction project*/
.text_space{
	margin-top:70px;
}
.main-class .icofont{
	clear:both;
	color:#f2ae2b;
	font-size:18px;
}
.main-class .icon1{
	float: left;
    margin-left: 20px;
}
.main-class .icon1 h6{
	font-size:15px;
	font-family: 'Poppins', sans-serif;
	font-weight:500;
	margin-top:0;
	margin-bottom:0;
	color:#ffffff;
	
}
.main-class{
	position:relative;
	
}
.icon-class{
	position:absolute;
	bottom:20px;
}
.Guidlines{
	margin-left: 100px;
    margin-right:0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    margin-top: -47px;
    position: relative;
    background-color: #ffffff;
	padding-bottom: 70px;	
}
.direction span{
	float:right;
	color:#898989;
	font-family: 'Poppins', sans-serif;
	font-size:13px;
	font-weight:400;
} 
.Guidlines h4{
	color:#003366;
	font-size:18px;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	padding-top:15px;
	padding-bottom:10px;
}
.Guidlines p{
	font-size: 15px;
    color: #898989;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
}
.Guidlines .sim-button1{
	width:172px;
	border-radius:3px;
	border:none;
	background-color:#01477f;
	padding-left:0;
	padding-right:0;
	font-family: 'Poppins', sans-serif;
	margin-top:0;
	margin-bottom:0;
	margin-left:0;
	margin-right:0;
	font-size:15px;
	margin-top:20px;
	text-transform:capitalize;
}

.button12::before{
	content: '';
	position: absolute;
	border-radius:3px;
	border:none;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	background-color:#f2ae2b; 
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.button12:hover::before{
	opacity: 1;
	width: 100%;
}
.direction h6{
	color: #f2ae2b;
	font-size:15px;
	font-family: 'Poppins', sans-serif;
	font-weight:500;
	margin-top:0;
	margin-bottom:0;
}

.sidebar_left{
	padding-right: 60px;
	width:30%;
}
.blog_posts .maincontent{ 
	padding-left:60px;
	width:70%
}
.blog_posts .sidebar_left h4{
	margin-bottom:20px;
	
}
.blog_posts .pagination_1{
	float:left;
	padding-top: 70px;
}
.blog_posts .button_request{
	float: left;
    margin-top: 20px;
    clear: both;
}

/* Blog-Small page css */

.blog_small .single_post{
	margin-bottom:80px;
}
.sidebar_right{
	width:30%;
	padding-left:55px;
}
.blog_small .sidebar_right h4 {
    margin-bottom: 20px;
}
.main_blog{
    width: 70%;
    padding-right: 55px;
    padding-left: 0;

}

.blog_small .pagination_1 {
    float: none;
    padding-top: 0;
    margin: 0 auto;
}
.blog_small .form-group button[type="submit"]{
	padding-right:30px;
}

/* Single Blogpost page css */

.Guidlines{
	padding-bottom:60px;
}

/*tagged*/

.tagged h3 {
    display: inline-block;
   color: #898989;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
    background-color: #f0f3f4;
    padding: 10px 17px 10px 17px;
    border-radius: 3px;
    margin-top: 0;
    margin-bottom: 0;
	    margin-right: 12px;
}
.tagged h3 a{
	color: #898989;
   text-decoration:none;
}
.tagged .social_icon {
	margin-top: 40px;
	float:right;
}
.tagged .social_icon p {
    display: inline-block;
    font-size: 13px;
    color: #f2ae2b;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
	margin-bottom: 0;
}
.tagged .social_icon ul {
    display: inline-block;
    list-style-type: none;
    padding-left: 0;
}
.tagged .social_icon li {
    display: inline-block;
}
 .tagged h2 {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    color: #2c2034;
    font-weight: 700;
    margin-top: 0px;
	margin-bottom: 20px;
}
 .tagged{
	margin-top:45px;
}
.tagged .social_icon ul li a{
	font-size: 15px;
	margin-left: 0;
	color: #c5c5c5;
	text-decoration: none;
	margin-right: 10px;
}
.tagged .social_icon ul li.last a{
	margin-right:0;
}
.tagged .social_icon a:hover{
	color:#003366;
}


/*Comment*/
.three-comment{
	padding-top:70px;
	padding-bottom:70px;
	clear:both;
}
.three-comment .three-comment_1{
	padding: 20px;
	background-color:#f0f3f4;
	position:relative;
	margin-top:25px;
}
.three-comment .three-comment_1 .comment_img{
	float:left;
	display:inline-block;
	padding-bottom:20px;
	padding-right:20px;
	
}
.three-comment .comment_text h6{
	font-size:16px;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	color:#003366;
	margin-top:0;
	margin-bottom:13px;
}
.three-comment .comment_text p{
	font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #898989;
	margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 98px;
    line-height: 26px;
}
.three-comment .comment_text .icofont{
	color: #003366;
    font-size: 14px;
    font-weight: 400;
}
.three-comment .comment_text span{
	color:#060606;
	font-weight:600;
	font-family: 'Poppins', sans-serif;
}
.three-comment .comment_text .icofont-reply{
	padding-left:20px;
}
.three-comment .comment_text span a{
	display:inline-block;
	font-size:14px;
	font-family: 'Poppins', sans-serif;
	color:#f2ae2b;
	margin-top:0;
	margin-bottom:0;
}
.three-comment h2{
	font-size: 16px;
    font-family: 'Poppins', sans-serif;
    color: #2c2034;
    font-weight: 700;
	margin-top:0;
	margin-bottom:0;
}
.space_left{
	margin-left:50px;
	
}

/*Post a Comment*/
.post_a_comment h2{
	font-size: 16px;
    font-family: 'Poppins', sans-serif;
    color: #2c2034;
    font-weight: 700;
	margin-top:0;
	margin-bottom:20px;
}

.post_a_comment input{
	box-shadow:none;
	border-radius:unset;
	margin-bottom:25px;
	padding-bottom:0;
	padding-top:0;
	font-family: 'Poppins', sans-serif;
    color: #898989;
    background: #f5f7fa;
    border: 0;
    height: 41px;
    border-radius: 2px;
    padding-left: 20px;
}
.post_a_comment textarea{
	background-color: #f0f3f4;
	border-radius:unset;
	border:none;
	box-shadow:none;
	margin-bottom:0;
	padding-bottom:0;
	height: 178px;
	padding-top: 15px;
}

.post_a_comment .sim-button5{
	margin:0 auto;
	margin-top:24px;
	
}
.post_a_comment .form-group{
	margin-bottom:0;	
}
.post_a_comment input[type="text"]::placeholder{
	font-family: 'Poppins', sans-serif;	
}
.post_a_comment textarea::placeholder{
	font-family: 'Poppins', sans-serif;
}
.post_a_comment textarea:focus{
	box-shadow:none;
}
.post_a_comment .button8 span{
	position:relative;
}
.post_a_comment .sim-button{
	padding:0;
	border: 0;
	margin-top: 25px;
}
.blog_posts .post_a_comment .form-group input{
	background-color: #f0f3f4;
	box-shadow: none;
}
.blogcontent {
    padding-right: 55px;
    width: 70%;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* - SHOP-PAGE */
/*-------------------------------------------------------------------------------------------------------------------------------*/

.technologies_02{
	box-shadow: none;
}

.technologies_02 .single-sec{
	background: #f5f7fa;
}


/*-----------------------------------------------------------------------------------------*/
/* - shop home page Css */
/*-----------------------------------------------------------------------------------------*/
.featured_products{	
	background: url(http://via.placeholder.com/1920x745) no-repeat;
	background-size:cover;
	position:relative;
	padding-bottom:100px;
}
.featured_products .our_team1 .simple-text{
	width:580px;
	margin:0 auto;
	margin-bottom:50px;
	text-align:center;
	font-size:15px;
	color:#707d98;
	font-family: 'Poppins', sans-serif;
	line-height:24px;
	margin-bottom:0;
}
.featured_products .our_team1 .simple-text p{
	margin-bottom:0;
}
.featured_products .our_team1 .tt-title{
	text-align:center;
	font-size:30px;
	color:#003366;
	font-family: 'Poppins', sans-serif;
	font-weight:700;
	margin-top:0;
	margin-bottom: 15px;
}
.featured_products .project_slider{
	margin-top:50px;
}
.featured_products .service_machine {
    position: relative;
    width: 100%;
	height:100%;
}
.featured_products .service_machine_shadow img{
  display: block;
  width: 100%;
  height:100%;
  
}
.featured_products .owl-nav{
	display:none;
}
.featured_products .service_machine_shadow{
	overflow:hidden;
}
.featured_products .service_machine .overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
}
.featured_products .service_machine_shadow:hover .overlay{
	opacity:1;
   background-color: #476f96;
}

.featured_products .service_machine_shadow .text{
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size:16px;
   font-family: 'Poppins', sans-serif;
}
.sim-button05 {
	padding-top:10px;
	padding-bottom:10px;
	width:154px;
     cursor: pointer;
     background-color:#f2ae2b;
     text-transform:capitalize;
     font-size:16px;
     font-family: 'Poppins', sans-serif;
     font-weight:500;
	border-radius:25px;
}
 .button011{
	color:#003366;
     -webkit-transition: all 0.5s;
     -moz-transition: all 0.5s;
     -o-transition: all 0.5s;
     transition: all 0.5s;
     position: relative;
	 border-radius:25px;
}
 .button011 a{
     color:#003366;
     text-decoration: none;
     display: block;
}
 .button011 span{
     z-index: 2;
     display: block;
     position: relative;
     width: 100%;
     height: 100%;
}
 .button011::before{
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 0%;
     height: 100%;
     z-index: 1;
     opacity: 0;
	 border-radius:25px;
     background-color:#ffffff;
     -webkit-transition: all 0.3s;
     -moz-transition: all 0.3s;
     -o-transition: all 0.3s;
     transition: all 0.3s;
}
 .button011:hover::before{
     opacity: 1;
     width: 100%;
}

/* service_machine_shadow */
.service_machine_shadow{
	background:#f5f7fa;
	border:3px solid #ffffff;
	box-shadow: 0px 8px 43px 0px rgba(0, 0, 0, 0.05);
	padding-bottom:20px;	
}

.service_machine_shadow img{
	width:100%;
}
.service_machine_shadow h2{
	font-size:18px;
	margin-top:0;
	margin-bottom:0;
	text-align:center;
	padding-top:20px;
	padding-bottom:10px;
	color:#003366;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
}
.service_machine_shadow h2 a{
	color:#003366;
	text-decoration:none;
}
.service_machine_shadow p{
	text-align:center;
	margin-bottom:0;
	color:#707d98;
	font-size:15px;
	font-weight:400;
}
.shop_post h1{
	font-size:18px;
	color:#707d98;
	font-weight:400;
	margin-top:0;
	margin-bottom:0;
	padding-top:125px;
	padding-bottom:40px;
	font-family: 'Poppins', sans-serif;
}

.border_left_right {
    border-left: 1px solid #707d98;
    font-size: 15px;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}


/*-----------------------------------------------------------------------------------------*/
/* - shop details page Css */
/*-----------------------------------------------------------------------------------------*/


.shop_post .brand_img img{
	width:93%;
	box-shadow: 0 8px 49px rgba(0, 0, 0, 0.1);
}

.shop_post .col-md-3{
	padding-right:15px;
	padding-left:15px;
	padding-top:0px;
}
.shop_post .form-group input[type="text"] {
    position: relative;
    width: 100%;
    background: #f5f7fa;
    border: 0;
    height: 41px;
    border-radius: 2px;
    padding-left: 20px;
}
.shop_post h4 {
    margin-bottom: 20px;
}
.shop_post .sidebar .search{
	clear:both;
	    position: relative;
}

/*Add to cart button*/

 .shop_post .brand_heading .sim-button {
     text-align: center;
     width:154px;
     background-color:#01477f;
     color:#ffffff;
     cursor: pointer;
     border-radius: 25px;
     font-size:16px;
 font-family: 'Poppins', sans-serif;
     font-weight:400;
	 margin-top:35px;
     padding:0px 25px 10px 25px;
	 margin-left:0;
}
 .shop_post .brand_heading .button1 {
     transition: all 0.5s;
     position: relative;
}
.shop_post .brand_heading a {
     text-decoration: none;
     display: block;
}
 .shop_post .brand_heading .sim-button:hover {
     border-radius: 25px;
	 background-color:#f2ae2b;
}
.shop_post .brand_heading h2{
	margin-top:0;
	margin-bottom:5px;
	font-size:18px;
	color: #003366;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
}
.shop_post .brand_heading .icofont{
	font-weight:500;
	line-height:44px;
	color: #fff;
}
.shop_post .brand_heading span{
	color:#f2ae2b;
	font-size:18px;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	width:44px;
}
.shop_post .brand_heading p{
	color: #898989;
    font-size: 15px;
	font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding-top: 30px;
    margin-bottom: 0;
    line-height: 26px;
}
.shop_post .brand_heading p span{
	color: #003366;
	font-size:15px;
	font-weight:600;
}
.shoptag_space_top{
	margin-top:70px;
}
.shop_tagged h6{
	display: inline-block;
    color: #898989;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
    background-color: #f0f3f4;
    padding: 10px 17px 10px 17px;
    border-radius: 3px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 12px;
}
.shop_tagged h6 a{
	color: #898989;
	text-decoration:none;
}
.shop_categories p{
	padding-top: 0;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #898989;
}
/*Shop_features*/
.Shop_features{
		margin-top:70px;
	}
 .shop_post .Shop_features .sim-button {
     text-align: center;
     width:154px;
     background-color:#01477f;
     color:#ffffff;
     cursor: pointer;	
     border-radius: 25px;
     font-size:16px;
 font-family: 'Poppins', sans-serif;
     font-weight:400;
     padding:10px 25px 10px 25px;
}
.shop_post .Shop_features a{
	display:inline-block;
	text-decoration:none;
}

.shop_post .Shop_features .sim-button{
	width: auto;
    padding: 0px 25px 10px 25px;
    color: #898989;
    background-color: #f0f3f4;
    margin-right: 8px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0;
    font-size: 15px;
	font-weight: 500;
}
.shop_post .Shop_features .sim-button:hover {
     border-radius: 25px;
	 background-color:#01477f;
	 color:#ffffff;
}
.shop_post .Shop_features p{
	padding-top:30px;
	margin-top:0;
	margin-bottom:0;
	font-size:15px;
	color: #898989;
	font-family: 'Poppins', sans-serif;
	font-weight:400;
}
.shop_post .Shop_features .p1{
	padding-top:20px;
	margin-top:0;
	margin-bottom:0;
}
.shop_post .shoptag_space_top h4{
	color:#2c2034;
}
$input-number-size: 80px;

.input-number-group {
  display: flex;
  justify-content: center;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
  }

  .input-group-button {
    line-height: calc(#{$input-number-size}/2 - 5px);
  }

  .input-number {
    width: $input-number-size;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
    display: block;
    margin: 0;
  }

  .input-number,
  .input-number-decrement,
  .input-number-increment {
  
    height: $input-number-size/2;
    user-select: none;
    border-radius: $global-radius;
  }

  .input-number-decrement,
  .input-number-increment {
    display: inline-block;
    width: $input-number-size/2;
    background: $light-gray;
    color: $body-font-color;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 400;
  }
  .input-number-decrement {
    margin-right: 0.3rem;
  }
  .input-number-increment {
    margin-left: 0.3rem;
  }
}
.input-number-group {
  display: flex;
   margin-top: 30px;
}

.input-number-group input[type=number]::-webkit-inner-spin-button,
.input-number-group input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
          appearance: none;
}

.input-number-group .input-number {
  width: 75px;
  text-align: center;
  display: block;
  margin: 0;
}

.input-number-group .input-number,
.input-number-group .input-number-decrement,
.input-number-group .input-number-increment {
  border: 1px solid #ebebeb;
  height: 44px;
  border-radius: 0;
  color:#003366;
  font-size:18px;
}

.input-number-group .input-number-decrement,
.input-number-group .input-number-increment {
    display: inline-block;
	border: 1px solid #ebebeb;
    width: 40px;
    background: #f2ae2b;
    color: #fff;
    text-align: center;
    border: #f2ae2b;
    font-weight: bold;
    cursor: pointer;
    font-size: 1;
    font-weight: 400;
}
/* service_machine_shadow */
.service_machine_shadow{
	background:#f5f7fa;
	border:3px solid #ffffff;
	box-shadow: 0px 8px 43px 0px rgba(0, 0, 0, 0.05);
	padding-bottom:20px;	
}

.service_machine_shadow img{
	width: 100%;

}
.service_machine_shadow h2{
	font-size:18px;
	margin-top:0;
	margin-bottom:0;
	text-align:center;
	padding-top:20px;
	padding-bottom:10px;
	color:#003366;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
}
.service_machine_shadow h2 a{
	color:#003366;
	text-decoration:none;
}
.service_machine_shadow p{
	text-align:center;
	margin-bottom:0;
	color:#707d98;
	font-size:15px;
	font-weight:400;
}
.shop_post h1{
	font-size: 18px;
    color: #898989;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 90px;
    padding-bottom: 40px;
    font-family: 'Poppins', sans-serif;
}

/*-----------------------------------------------------------------------------------------*/
/* -Shop cart page Css */
/*-----------------------------------------------------------------------------------------*/

.shop_cart .table-bordered{
	border-top:2px solid #e0e4eb;
	border-right:none;
	border-left:none;
	border-bottom:none;
	padding:0;
}
.shop_cart table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.shop_cart table tr {
  background-color:#f5f7fa;
}

.shop_cart table td {
  text-align: center;
  vertical-align:middle;
  padding:0;
 color:#707d98;
}

.shop_cart table th {
  font-size: 14px;
  text-transform: capitalize;
  color:#232323;
  font-weight:600;
  text-align:center;
}
.shop_cart table tr td{
	vertical-align:middle;
}
.shop_cart table tr th .procuct_left{
	 font-size:14px;
	 font-weight:600;
	 text-align:left;
	 margin-top:0;
	 margin-bottom:0; 
}
.shop_cart table tr .p01{
	text-align:left;
	margin-bottom:0;
	padding-bottom:10px;
}
.shop_cart table tr .p02{
	text-align:left;
	margin-bottom:0;
}
.shop_cart table tr .p03{
	text-align:left;
	margin-bottom:0;
}
.shop_cart .table tr td input[type="text"]{
	text-align:center;
	width:64px;
	height:28px;
	margin:0 auto;
	vertical-align:middle;
	border:1px solid #e0e4eb;
}
.shop_cart .table tr .icofont{
	font-size:24px;
	color:#f2ae2b;
}
.shop_cart .table tr td img{
	display:initial;
	padding-left:40px;
	padding-right:40px;
}
.shop_cart .table-bordered>tbody>tr>td{
	border:2px solid #e0e4eb;
}
.shop_cart .table-bordered>thead>tr>th{
	border:2px solid #e0e4eb;
}
.shop_cart .table tr .change_color{
	color:#003366;
}
.shop_cart .table tr td span{
	color:#f2ae2b;
}
.shop_cart .table tr td a{
	text-decoration:none;
}
/*-----------------------------------------------------------------------------------------*/
/* - shopping section Css */
/*-----------------------------------------------------------------------------------------*/
.shopping{
	background-color:#f5f7fa;
}
.shopping .coupon_code h2{
	font-weight: 600;
	font-size:18px;
	color:#003366;
	font-family: 'Poppins', sans-serif;
	margin-top:0;
	margin-bottom:0;
	padding-bottom:12px;
}
.shopping .coupon_code p{
	font-weight:400px;
	font-size:15px;
	color:#707d98;
	font-family: 'Poppins', sans-serif;
	margin-top:0;
	margin-bottom:0;
}
.shopping .coupon_code .p_01{
	padding-bottom:15px;
}
.shopping .coupon_code input[type="text"]{
	box-shadow:none;
	border-radius:2px;
	border:2px solid #eeeff0;
	background-color:#fcfcfc;
	padding-bottom:22px;
	padding-top:22px;
	padding-bottom:22px;
}
.shopping .coupon_code .sim-button {
     text-align: center;
     width:172px;
     background-color:#01477f;
     color:#ffffff;
     cursor: pointer;
     border-radius: 2px;
     font-size:16px;
     font-family: 'Poppins', sans-serif;
     font-weight:400;
	 padding-top:0px;
	 padding-bottom:0px;
	 float:left;
	 margin-top:23px;
}
 .shopping .coupon_code .button1 {
     transition: all 0.5s;
     position: relative;
}
.shopping .coupon_code a {
     text-decoration: none;
     display: block;
}
 .shopping .coupon_code .sim-button:hover {
     border-radius: 2px;
	 background-color:#f2ae2b;
}
.shopping .coupon_code .grand_total .sim-button{
	width:100%;
	border:2px solid #eeeff0;
	border-radius:none;
	background-color:#f2ae2b;
	padding-top:0px;
	padding-bottom:10px;
	font-size:18px;
	margin-top:15px;
}
.shopping .coupon_code .grand_total .sim-button:hover{
	width:100%;
	background-color:#01477f;
}
.shopping .coupon_code .checkout .sim-button{
	width:100%;
	background-color:#01477f;
	font-size:16px;
}
.shopping .coupon_code .checkout .sim-button:hover{
	width:100%;
	background-color:#f2ae2b;
}
.shopping .coupon_code h3{
	margin-top:0;
	margin-bottom:0;
	font-size:18px;
	color:#707d98;
	font-family: 'Poppins', sans-serif;
	text-align:center;
}
.shopping .sub_total p{
	padding-top:20px;
	padding-left:10px;
	clear: both;
	text-align: center;
}
.shopping .sub_total .checkout{
	padding-left:10px;
	padding-right:10px;
}

/* team_page css */

.team_page .Specialistt{
	margin-bottom:40px;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/*  404 PAGE  */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .error_page{
	 background: url(http://via.placeholder.com/1920x971) no-repeat;
     background-size:cover;
}
 .cell-view {
     display: table-cell;
     vertical-align: middle;
     width: 10000px;
     padding: 15px 0;
}
 .pageErroreTitle .cell-view {
     height: 974px;
}
 .pageErroreTitle {
     position: relative;
     text-align: center;
}
 .pageErroreTitle .as {
     font-size: 200px;
     line-height: 146px;
     font-family: 'Poppins', sans-serif;
     font-weight: 700;
     color: #fff;
     margin-top: 0;
     margin-bottom: 30px;
}
 .pageErroreTitle p {
     color: #fff;
     font-size: 50px;
     line-height: 60px;
     font-family: 'Poppins', sans-serif;
     font-weight: 700;
     margin-bottom: 15px;
}
 .pageErroreTitle span {
     font-size: 25px;
     line-height: 30px;
     color: #fff;
     font-family: 'Poppins', sans-serif;
     font-weight: 300;
}
 .error_shadow{
     background: rgba(0, 0, 0, 0.5);
     padding-top: 90px;
     padding-bottom: 95px;
}
 .error_page .error{
     width:100%;
}
 .error_page .error .button8 span{
     font-size: 20px;
     font-weight: 600;
     font-family: 'Poppins', sans-serif;
     line-height: 64px;
     color:#fff;
	 text-transform: uppercase;
     font-weight:700;
}
 .error_page .error .button8:hover span{
     color:#fff;
}
 .error_page .error .button8{
     width:303px;
     height:64px;
     line-height:64px;
     float:none;
}
 .error_page a{
     text-decoration:none;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 20 - COMMING SOON PAGE CSS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .commingWrapper .cell-view {
	 height: 974px;
}
 .commingTitle .as {
     text-align: center;
     font-size: 70px;
     line-height: 60px;
     margin-bottom: 45px;
     color: #fff;
     font-family: 'Poppins', sans-serif;
     font-weight: 700;
     margin-top: 0;
}
 .timerWrapper {
     text-align: center;
     margin-bottom: 40px;
     border-top: 1px solid #dddddd;
     border-bottom: 1px solid #ddd;
}
 .timerWrapper .timerBlock {
     display: inline-block;
     position: relative;
     border: 0;
     border-radius: 100%;
     padding: 40px 33px;
     min-width: 170px;
     margin: 0 17px;
}
 .timerWrapper .timer {
     display: inline-block;
     font-size: 60px;
     line-height: 70px;
     color: #fff;
     font-style: normal;
     text-align: center;
     position: relative;
     top: -21px;
     left: -4px;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
}
 .timerWrapper .timerBlock span {
     color: #fff;
     font-size: 20px;
     line-height: 36px;
     text-transform: uppercase;
     position: absolute;
     bottom: 20px;
     left: 50%;
     -webkit-transform: translateX(-50%);
     transform: translateX(-50%);
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
}
 .commingWrapper p {
     font-family: 'Poppins', sans-serif;
     color: #fff;
     font-size: 15px;
     text-align: center;
     font-weight: 400;
     padding-bottom: 30px;
     width:750px;
     line-height:26px;
     margin:0 auto;
}
 .commingWrapper{
	 background: url(http://via.placeholder.com/1920x971) no-repeat;
     background-size:cover;
     position:relative;
}
 .commingWrapper:before{
     position:absolute;
     left:0;
     top:0;
     bottom:0;
     content:'';
     background: rgba(0, 0, 0, 0.6);
     right:0;
}
 .comming_shadow{
     width:830px;
     margin:0 auto;
}
 .commingWrapper .sim-button{
     font-family: 'Poppins', sans-serif;
     font-weight:700;
     font-size:14px;
     color:#fff;
     width: 196px;
     height: 55px;
     line-height: 55px;
     border: 0;
     border-radius:0;
}
 .commingDescription form .simple-input {
     border: 0;
     max-width: 545px;
     border-radius: 0;
     margin-right: 22px;
     height: 55px;
     display: inline-block;
     float: left;
     width: 100%;
     padding-left: 20px;
     font-size: 17px;
     color: #797979;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
}
 .commingWrapper form{
     margin: 0 auto;
     width: 765px;
}
 .commingWrapper a{
     text-decoration:none;
}
 .commingDescription .wrapper-inner-tab-backgrounds-first{
     float:none;
}
 .simple-input {
     font-size: 17px;
     color: #797979;
     line-height: 48px;
     height: 48px;
     padding: 0 20px;
     width: 100%;
     border: 1px solid #e0e0e0;
     background: #fff;
     font-family: 'Poppins', sans-serif;
     transition: all .15s;
     -webkit-transition: all .15s;
}

.commingWrapper .sim-button {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    width: 196px;
    height: 55px;
    line-height: 55px;
    border: 0;
	float: right;
    border-radius: 0;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*  MAINTENANCE PAGE  */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .maintenance{
	 background: url(http://via.placeholder.com/1920x971) no-repeat;
     background-size:cover;
     position:Relative;
	 background-position:center;
}
 .maintenance:before{
     position:absolute;
     content:'';
     left:0;
     right:0;
     top:0;
     bottom:0;
     background: rgba(0, 26, 87, 0.56);
}
 .maintenance .pageErroreTitle1{
     position:relative;
}
 .maintenance .maintenance_text h1{
     font-family: 'Poppins', sans-serif;
     font-size:70px;
     color:#fff;
     font-weight: 700;
     margin-bottom:0;
     text-align:center;
     margin-top: 0;
     margin-bottom: 20px;
}
 .maintenance .maintenance_text p{
     font-size:22px;
     color:#ffffff;
     font-family: 'Poppins', sans-serif;
     font-weight:400;
     font-style:italic;
     text-align:center;
     margin-bottom: 20px;
}
 .maintenance_text h6{
     font-size:18px;
     color:#ffffff;
     font-family: 'Poppins', sans-serif;
     font-weight:400;
     text-align:center;
     line-height: 26px;
}
 .maintenance_text{
	 padding: 304.5px 0;
}
 .single-progress {
     margin-top: 70px;
}
 .barfiller {
     padding: 0;
     background: #fff;
     border: 0;
     height: 6px;
     width: 713px;
     height:19px;
     margin:0 auto;
     position: relative;
}
 .barfiller .tipWrap {
     display: none;
}
 .barfiller .tip {
     background: #000000;
     border-radius: 1px;
     color: #fff;
     font-size: 14px;
     font-family: 'Poppins', sans-serif;
     left: 0;
     line-height: 20px;
     margin-top: -52px;
     padding: 8px 16px 8px 16px;
     position: absolute;
     text-align: center;
     z-index: 2;
     margin-left: 0;
     border-radius: 5px;
}
 .barfiller .tip:after {
     border-color: #000 transparent;
     border-image: none;
     border-style: solid;
     border-width: 11px 14px 0px 14px;
     content: "";
     display: block;
     left: 0;
     position: absolute;
     top: 86%;
     left: 27%;
     -webkit-transform: rotate(-92deg);
     -ms-transform: rotate(-92deg);
     transform: rotate(360deg);
     z-index: 9;
}
 .barfiller .fill {
     background: #f2c21a;
     display: block;
     width: 0px;
     height: 100%;
     z-index: 1;
}
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* TESTIMONIAL  PAGES  */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .testimonial_sec .image{
     vertical-align: middle;
     width: 100%;
     display: inline-block;
}
 .testimonial_sec .image img{
	 margin:0 auto;
 }
 .testimonial_sec .content {
     display: inline-block;
     vertical-align: middle;
}
 .testimonial_sec .simple-text p {
    font-size: 17px;
    color: #898989;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
	text-align:left;
}
 .testimonial_sec .content h3 {
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     font-size:30px;
     color:#2a2a2a;
     margin-top: 0;
     margin-bottom: 10px;
}
 .testimonial_sec .content h5 {
     font-family: 'Poppins', sans-serif;
     font-weight: 400;
     font-size:15px;
     color:#898989;
     margin-bottom: 10px;
}
 .star_rating{
     color:#fdb714;
}
 .testimonial_sec .simple-text p{
     margin-top:20px;
}
.testimonial_sec .icon{
	font-size:40px;
	color:#f2c21a;
	text-align: center;
    margin-top: 20px;
}
 .testi1{
     background:#f1f1f1;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/*  FAQ PAGE  */
/*-------------------------------------------------------------------------------------------------------------------------------*/
 .tt-accordeon {
     position: relative;
}
 .tt-accordeon-title {
     background-color: #fafafa;
     display: block;
     position: relative;
     font-size: 16px;
     line-height: 20px;
     font-weight: 600;
     color: #2a2a2a;
     padding: 15px 55px 15px 20px;
     border: 1px solid #dddddd;
     cursor: pointer;
     text-align: left;
     letter-spacing: 0;
     font-family: 'Poppins', sans-serif;
}
 .tt-accordeon-title:after {
     position: absolute;
     content: "\f067";
     top: 50%;
     right: 0;
     margin-top: -26px;
     width: 0;
     height: 0;
     z-index: 2;
     font-family: FontAwesome;
     -webkit-transition: all 0.2s linear;
     -moz-transition: all 0.2s linear;
     transition: all 0.2s linear;
     background: #003366;
     width: 57px;
     height: 51px;
     padding: 23px;
     padding-top: 16px;
     color: #ffff;
}
 .tt-panel {
     display: none;
     background-color: #fff;
	 padding: 25px 25px;
     border-top: 0px;
     border-left: 1px solid #d1d1d1;
     border-right: 1px solid #d1d1d1;
     border-bottom: 1px solid #d1d1d1;
}
 .tt-accordeon-title.active:after {
     position: absolute;
     content:"\f068";
     -webkit-transition: all 0.2s linear;
     -moz-transition: all 0.2s linear;
     transition: all 0.2s linear;
}
 .faq_form{
     background:#f3f3f3;
}
 .c-input {
     width: 100%;
     height: 45px;
     font-size: 15px;
     font-weight: 400;
     color: #898989;
     padding: 0 20px;
     background: #fff;
     border: 1px solid #dddddd;
     font-family: 'Open Sans', sans-serif;
}
 .c-area {
     width: 100%;
     height: 172px;
     font-size: 15px;
     line-height: 18px;
     font-weight: 400;
     color: #595959;
     padding: 15px 20px;
     background: #fff;
     border: 1px solid #eeeeee;
     font-family: 'Open Sans', sans-serif;
}
 .faq_form .sim-button{
     float:none;
     color: #fff;
}


.faq_section .tt-title span {
    color: #003366
}
.tt-panel .simple-text p{
	text-align:left;
}
.faq_section .tt-title{
	margin-bottom:30px;
}
.faq_form .tt-title{
	margin-bottom:30px;
}
.faq_form .tt-title span{
	color:#003366;
}
.faq_form  .button8 span{
	position:relative;
}
.faq_form .sim-button{
	padding:0;
	border:0;	
}
.faq_form  .button{
	text-align:center;
}

/*-----------------------------------------------------------------------------------------*/
/* - Company History Page Css */
/*-----------------------------------------------------------------------------------------*/

.company_hostory{
	background:#f1f1f1
}
.company_hostory .simple-text p{
	text-align:left;
}
.company_hostory .simple-text{
	width:580px;
	margin:0 auto;
	text-align:center;
}
.company_hostory .year{
	padding: 6px 20px;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: -17px;
    z-index: 9;
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 100px;
    text-align: center;
	background:#f2c21a;
}
.company_hostory .company_detail .simple-text{
	width:100%;
	text-align:left;
}
.company_hostory .company_detail .col-md-4{
	margin-top:70px;
	
}
.company_hostory .company_detail .content{
	background:#fff;
	padding:30px;
}
.company_hostory .tm-ourhistory-row {
    display: -webkit-box;
    display: flex;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
.tm-ourhistory .tm-ourhistory-right {
  padding-bottom: 30px; 
      padding-left: 50px;
  }
  .tm-ourhistory .tm-ourhistory-right:before {
    content: '';
    position: absolute;
    top: 33px;
    left: -8px;
    z-index: 2;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 5px solid #003366;
    box-sizing: content-box;
    background: #f2ae2b;
}
.tm-ourhistory .label {
	display: block;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-top: 25px;
	background: #003366;
    padding: 10px 0;
	width: 130px;
    font-family: 'Poppins', sans-serif;
}
.tm-ourhistory .title {
    display: block;
    font-size: 18px;
    font-weight: 600;
	color: #2a2a2a;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 5px;
}
.tm-ourhistory .tm-ourhistory-right:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 1;
    width: 3px;
    height: 100%;
	background: #ccbcbc;
}
.tm-ourhistory .tm-ourhistory-right.last{
	padding-bottom:0;
}

/*-----------------------------------------------------------------------------------------*/
/* - Company Overview Page Css */
/*-----------------------------------------------------------------------------------------*/

.overview img{
	border-radius:10px;
	
}
.overview .simple-text p{
	text-align:left;
	font-size:16px;
}
.overview h1{
	font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 400;
    color: #003366;
    margin-top: 0px;
	margin-bottom: 25px;
	line-height:26px;
}

.overview h1 span{
    color: #f2ae2b;
}
.overview .sim-button{
    float: left;
}
.company_stablish{
	background:#fff;
}
.company_stablish h1{
	font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 400;
    color: #003366;
    margin-top: 0px;
    margin-bottom: 25px;
    line-height: 26px;
}
.company_stablish h1 span{
	 color: #f2ae2b;
}
.company_stablish .simple-text p{
	text-align:left;
}
.company_stablish ul.listing{
	padding-left:0;
	margin-top:15px;
}
.company_stablish ul.listing li{
	padding-left:0;
	list-style: none;
    line-height: 30px;
    font-size: 16px;
    color: #898989;
    font-weight: 400;
    padding-left: 35px;
    font-family: 'Poppins', sans-serif;
}
.company_stablish ul.listing li:before {
    content: "\f00c";
    position: absolute;
    font-family: FontAwesome;
    left: 18px;
    color: #f2c21a;
}
.service-tab-box .tab-menu {
     border-bottom: 1px solid #f4f4f4;
     overflow: hidden;
     padding: 0;
     margin-bottom: 0;
}
 .service-tab-box .tab-menu li {
     display: inline-block;
     float: left;
     margin-bottom: 0px;
     margin-right: 3px;
}
 .service-tab-box .tab-menu li span {
     background: #ffffff;
     border-top: 1px solid #f4f4f4;
     border-left: 1px solid #f4f4f4;
     border-right: 1px solid #f4f4f4;
     border-bottom: 1px solid #f4f4f4;
     border-radius: 0;
     display: block;
     margin-bottom: -1px;
     padding: 15px 30px 13px;
     color: #2a2a2a;
     font-size: 17px;
     font-weight: 600;
     text-transform: capitalize;
     transition: all 500ms ease;
     font-family: 'Poppins', sans-serif;
     position: relative;
     display: block;
     cursor: pointer;
}
 .service-tab-box .tab-menu li.active span, .service-tab-box .tab-menu li:hover span{
     background: #f4f4f4;
     color: #003366;
     border-left-color: #f4f4f4;
     border-top-color: #f4f4f4;
     border-right-color: #f4f4f4;
     border-bottom-color: #f4f4f4;
}
 .service-tab-box .tab-content-box {
     background: #f4f4f4;
     padding: 29px 20px 20px;
     display: block;
}
 .service-tab-box .tab-content-box .single-tab-content{
}
 .service-tab-box .tab-content-box .single-tab-content .top-content {
     margin-bottom: 10px;
}
 .service-tab-box .tab-content-box .single-tab-content .top-content p{
     color:#252525;
     font-size:15px;
     font-weight:400;
	 font-family: 'Poppins', sans-serif;
     line-height:26px;
     margin-bottom:0;
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content {
     overflow: hidden;
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content .title {
     padding-bottom: 10px;
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content .title h3 {
     font-size: 20px;
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content ul {
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content ul.left{
     margin-right: 50px;
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content ul li{
     line-height: 36px;
}
 .service-tab-box .tab-content-box .single-tab-content .bottom-content ul li i{
     color: #3740b0;
     font-size: 14px;
     display: inline-block;
     padding-right: 8px;
}
.about_technologies_01{
	margin-top: 0;
    z-index: 1;
}
.about_technologies_01  .technologies .single-sec{
	padding-top:0;
}
/*-----------------------------------------------------------------------------------------*/
/* - Career Page Css */
/*-----------------------------------------------------------------------------------------*/


.new_openings h4{
	font-family: 'Poppins', sans-serif;
    color: #2a2a2a;
    font-weight:600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
}
.new_openings h5{
	font-family: 'Poppins', sans-serif;
    color: #898989;
    font-weight:400;
    font-size:16px;
	margin-top: 10px;
}
.new_openings .tt-title{
	text-align:center;
}

.new_openings ul.listing li{
    padding-left: 0;
    list-style: none;
    line-height: 30px;
    font-size: 16px;
	color: #252525;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding-left: 35px;
	
}
.new_openings ul.listing{
	padding-left:0;
}
.new_openings ul.listing li:before {
    content: "\f00c";
    position: absolute;
    font-family: FontAwesome;
    left: 35px;
    color: #f2ae2b;
}
.new_openings .responsibilies{
	margin-top:20px;
}

.apply_now_form input {
    color: #898989;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    box-shadow: none;
    outline: none;
}
.apply_now_form input:focus{
	box-shadow: none;
    outline: none;
}
.apply_now_form .form-control {
    border: 1px solid #dddddd;
    height: 49px;
    border-radius: 0;
    padding: 12px 15px
}
.apply_now_form{
	background:#f1f1f1;
}
.apply_now_form h3{
	text-align:center;
	margin-bottom: 30px;
}
.apply_now_form .sim-button{
	width: 300px;
    margin: 0 auto;
    float: none;
    margin-top: 20px;
	border:0;
	padding: 0;
}
.apply_now_form .button8 span{
	position:relative;
	padding:0;
	color: #fff;
}
.apply_now_form .button{
	text-align:center;
}
.apply_now_form textarea.form-control {
    height: 100px;
    padding-top: 15px;
    color: #898989;
    font-family: 'Open Sans', sans-serif;
}

/* slider images */

.hmslider1 .slide1{
	background-image: url(http://via.placeholder.com/1920x694);
}
.hmslider1 .slide2{
	background-image: url(http://via.placeholder.com/1920x694);
}
.hmslider1 .slide3{
	background-image: url(http://via.placeholder.com/1920x694);
}
.hmslider2 .slide1{
	background-image: url(http://via.placeholder.com/1920x567);
}
.hmslider2 .slide2{
	background-image: url(http://via.placeholder.com/1920x567);
}
.hmslider2 .slide3{
	background-image: url(http://via.placeholder.com/1920x567);
}
.hmslider3 .slide1{
	background-image: url(http://via.placeholder.com/1920x694);
}
.hmslider3 .slide2{
	background-image: url(http://via.placeholder.com/1920x694);
}
.hmslider3 .slide3{
	background-image: url(http://via.placeholder.com/1920x694);
}

.our_spacility_11{
	padding-bottom:100px;
}
/*-----------------------------------------------------------------------------------------*/
/* - Service Details Page Css */
/*-----------------------------------------------------------------------------------------*/

/* service detail page */
 .categoryList {
     position: relative;
     margin-bottom: 40px;
     padding: 0;
     list-style: none;
}
 .categoryList li {
     position: relative;
     margin-bottom: 5px;
}
 .categoryList li a {
     display: block;
     background: #f1f1f1;
     color: #2a2a2a;
     padding: 17px 29px;
     -webkit-transition: all 150ms linear;
     transition: all 150ms linear;
     font-size:16px;
     font-weight:600;
     font-family: 'Poppins', sans-serif;
     text-decoration:none;
	 border-left: 4px solid #f2ae2b;
}
 .categoryList li.activeCat a, .categoryList li a:hover {
     background: #354a50;
     color: #fff;
}
 .faq_query {
     background: url(../images/services/faq_querybg.jpg);
     background-repeat: no-repeat;
     background-position: right;
     background-size: cover;
     border: 2px solid #e9e9e9;
     padding: 25px;
}
 .tt-faq-title {
     color: #2a2a2a;
     font-family: 'Poppins', sans-serif;
     font-weight: 600;
     font-size: 20px;
     margin-top: 0;
}
 .faq_query p{
     color:#898989;
}
 .faq_query a {
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     color: #f2c21a;
     font-size: 15px;
     line-height: 35px;
}
 .pdfBlock {
     position: relative;
     border: 1px solid #e6e6e6;
     background: #003366;
     padding: 18px 77px 10px 80px;
     margin-top: 40px;
}
 .pdfBlock i{
     font-size:40px;
     color:#ffffff;
     position: absolute;
     left: 21px;
     top: 16px;
}
 .pdfBlock .pdfSize span {
     text-transform: uppercase;
     color: #ffffff;
     font-weight: 600;
     font-size: 15px;
     line-height: 18px;
     font-family: 'Poppins', sans-serif;
}
 .pdfBlock .pdfSize p {
	color: #f2ae2b;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
}
 .mainServicesContent {
     margin-left: 30px;
}
 .mainServicesContent .section_1 .right_sec{
     background:#f1f1f1;
     text-align:center;
     padding:30px;
     margin-top: 30px;
}
 .mainServicesContent .section_1 .right_sec i{
     color:#001a57;
     font-size:20px;
     vertical-align: middle;
}
 .mainServicesContent .section_1 .right_sec .simple-text p{
     font-size: 16px;
     padding-top: 15px;
     padding-bottom: 20px;
}
 .mainServicesContent .section_1 .right_sec h5{
     color:#f2c21a;
     font-family: 'Poppins', sans-serif;
     font-size:15px;
     font-weight:400;
     letter-spacing:0.30px;
     margin-bottom: 0;
}
 .mainServicesContent .section_1 .right_sec .fa-minus{
     color:#f2c21a;
}
 .mainServicesContent .section_1 .col-md-4 {
     width: 39.333333%;
}
 .mainServicesContent .section_1 .col-md-8 {
     width: 60.666667%;
}
 .mainServicesContent .section_2 ul li{
     line-height:36px;
     font-size:16px;
     color:#42435d;
     font-family: 'Open Sans', sans-serif;
     font-weight:400;
     list-style:none;
     padding-left: 35px;
}
 .mainServicesContent .section_2 ul{
     padding-left:0;
}
 .mainServicesContent .section_2 ul li:before{
     content:"\f00c";
     position:absolute;
     font-family: FontAwesome;
     left: 18px;
     color:#f2c21a;
}
.mainServicesContent .simple-text p{
	text-align:left;
}
 .section_4 ul{
     padding-left:0;
}
 .section_4 ul li{
     list-style:none;
     font-size:15px;
     color:#42435d;
     font-family: 'Open Sans', sans-serif;
     font-weight:400;
     line-height:30px;
}
 .tt-sub-title1 {
     font-family: 'Poppins', sans-serif;
     font-size: 24px;
     color: #ffffff;
     font-weight: 600;
     margin-top: 0;
     margin-bottom: 0;
     text-transform: initial;
}
 .tt-sub-title1 span{
     color:#001a57;
}
 .contact-form input {
     height: 45px;
     box-shadow: none;
     border-radius: 0;
     color: #898989;
     font-size: 15px;
     font-weight: 400;
     padding: 6px 17px;
     font-family: 'Open Sans', sans-serif;
}
 .section_4 .contact-form textarea.form-control {
     min-height: 137px;
}
 .contact-form textarea.form-control {
     height: auto;
     min-height: 204px;
     border-radius: 0;
     padding: 6px 20px;
     color: #959595;
     font-size: 16px;
     font-weight: 400;
     font-family: 'Poppins', sans-serif;
}
 .section_4 button{
     text-decoration:none;
     margin-top: 15px;
}
 .section_4 button:focus,.section_4 button:active{
     outline: none!important;
     box-shadow: none;
     border-color: #ddd;
}
 .section_2 .image{
     float:right;
     margin-top: 10px;
}
.service_details .blogAside .categoryList li a i {
    font-size: 16px;
    margin-top: 0px;
    float: right;
    font-weight: 700;
}
/* responsive */
@media(min-width:991px){
	.col-md-push-8 {
		left: 0;
	}
	.col-md-pull-4 {
		right: 0;
	}
}
.free_home select{
	height:44px;
}

/* House Renovation */
.Building_Renovation{
	padding-left:0;
	list-style:none;
	margin-bottom:0;
}
.Building_Renovation li{
	color: #898989;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
	line-height:36px;
}
.Building_Renovation i{
	 color:#f2ae2b;
	 padding-right:7px;
}
.House_Renovation_form input, .House_Renovation_form textarea{
	margin-bottom:0;
	font-family: 'Open Sans', sans-serif;
	border:1px solid #dddddd;
	background-color:#ffffff;
	border-radius: 2px;
}
.House_Renovation_form .form_btn .button8 span{
	position:relative;
	font-family: 'Poppins', sans-serif;
}
.House_Renovation_form .form_btn .btn{
	padding:0;
}
.House_Renovation_form .form_btn .sim-button{
	border:none;
	margin-top:15px;
}
.House_Renovation_form .text-left{
	text-align:left;
	margin-bottom:15px;
}

.services_all .simple-text .p1,.services_all .simple-text .p2{
	color:#595959;
	font-weight:500;
}
.services_all .simple-text .p2{
	background-color:#f4f4f4;
	padding:20px 30px;
}
.services_all .simple-text span{
	text-align:right;
	color:#f2ae2b;
	display:block;
}

.services_all .tt-accordeon-title{
	background-color:#f4f4f4;
	border:none;
}
.services_all .tt-accordeon-title:after{
	font-weight:400;
}
.roofing_form textarea{
	height:125px;
}
.section_2 .image.image_011 {
    float: none; 
    margin-bottom: 20px;
}

/*Home4*/

.hmslider4 .slide1{
	background-image:url("http://via.placeholder.com/1920x567");
}

.header4 .tt-header .main-nav>ul>li.active>a{
	color:#ffffff;
	background:#f2ae2b;
}
.header4 .tt-header{
	background:#ffffff;
}
.header4 .header_top{
	border-top: none;
	padding-top:25px;
	padding-bottom:22px;
	background:#003366;
}
.header4 .tt-header .main-nav > ul > li > a{
	padding:18px 23px;
}

.header4 .tt-header .toggle-block{
	float:left;
}
.header4 .header_top ul{
	background:none;
	box-shadow:none;
	width:auto;
	margin-bottom: 0px;
	margin-top: 0px;
}
.header4 .header_top .text h5 {
    color: #c5c5c5;
}
.header4 .header_top .text h6 a{
	color: #d8dde1;
    text-decoration: none;
    font-weight: 400;
}
.header4 .header_top .text h6{
	color: #c5c5c5;
	font-weight:400;
}
.header4 .header_top .icon i{
	color:#ffffff;
	font-size:18px;
	background:#f2ae2b;
	border-radius:50px;
	padding:13px 16px;
	vertical-align: middle;
}
.header4 .tt-header .main-nav>ul>li>a:hover{
	background-color:#f2ae2b;
	color:#ffffff;
}
.header4 .header_top .icon .fa{
	padding:15px 20px;
}
.header4 .header_top .text{
	vertical-align:middle;
}
.header4 .header_top ul li{
	margin-left: 62px;
}
.header4 .header_top .icon{
	   padding-right:20px;
}
.header4 .tt-header .main-nav>ul>li>a{
	color:#252525;
}
.welcome_section_4 .image{
	float:left;
	padding-left:45px;
	padding-top:5px;
}
.welcome_section_4 .image{
	float:left;
	padding-left:37px;
	box-shadow:none;
}

.welcome_section_4 .image img{
	box-shadow:none;
}
.welcome_section_4 .cellpadding_1{
	padding-left:15px;
	padding-right:49px;
}

.header4 .top-line-right .top-info .top-social a:hover{
	color:#003366;
}
.welcome_section_4 h2{
	margin-bottom:10px;
	margin-top:0;
}
.welcome_section h4{
	margin-bottom:10px;
}
.welcome_section_4 .buttons{
	margin-top:15px;
}
.welcome_section_4 .simple-text p{
	padding-bottom: 10px;
}
@media (max-width: 991px) {
    .header4 .tt-header .top-inner:before,.header4 .tt-header .top-inner:after {
         background: #ffffff;
    }
	.header4 .tt-header .main-nav > ul > li > a{
	padding:13px 15px 13px 15px;
}
  }
	
.Services_Key_Features_4{
	background:url("http://via.placeholder.com/1920x967");
	 position:relative;
	 background-size:cover;
	 background-attachment:fixed;
}
.Services_Key_Features_4:before{
		background-color:#003366;
		position:absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		content: '';
		opacity:.7;
	 } 
.Services_Key_Features_4 .tt-title {
    color: #ffffff;
	position:relative;
}
.Services_Key_Features_4 .simple-text p{
	color: #ffffff;	
	position:relative;
}
.services_04 .tt-title{
	text-align:left;
}
.services_04 .simple-text p{
	text-align:left;
	margin:0;
}
.services_04 .simple-text2 p{
	text-align:center;
}
.request_A_quote_04{
	margin-top:250px;
}
.our_customer_saying_04{
	margin-top:100px;
	background:url("http://via.placeholder.com/1920x611");
	position:relative;
	background-size:cover;
	background-attachment:fixed;
	padding-bottom:50px;
	background-position:center;
}
.our_customer_saying_04:before{
	background:#003366;
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	content: '';
	opacity:.75;
}
.our_customer_saying_04 .tt-title{
	color:#ffffff;
	position:relative;
}
.our_customer_saying_04 .simple-text p{
	color: #ffffff;
	position:relative;
}
.our_customer_saying_04 .our_customer{
   background: #ffffff;
    box-shadow: 0 8px 43px rgba(0, 0, 0, 0.1);
padding: 30px 15px 40px 30px;
}
.our_customer_saying_04 .our_customer .client{
	padding-left:0;
	padding-top:30px;
}
.services_04 .owl-carousel .owl-nav.disabled{
	display:block;
	position: absolute;
    right: 0;
    top: -96px;
	font-size:25px;
	color:#ffffff;
}
.services_04 .owl-carousel .owl-nav .owl-next{
	position: relative;
    top: -55px;
	background-color:#f2ae2b;
	padding:10px 15px 10px 15px;
}
.services_04 .owl-carousel .owl-nav .owl-prev{
	position: relative;
    right: 80px;
	background-color:#f2ae2b;
	padding:10px 15px 10px 15px;
}
	.our_customer_saying_04 .owl-carousel .owl-nav.disabled{
		display:block;
		color:#ffffff;
		position:relative;
		font-size:40px;
	}
	.our_customer_saying_04 .owl-carousel .owl-nav .owl-prev{
	position: relative;
    top: -164px;
    left: -50px;
	right:0;
}
.our_customer_saying_04 .owl-carousel .owl-nav .owl-next{
	position: absolute;
	top: -164px;
	right: -50px;
	}
	
/*-----------------------------------------------------------------------------------------*/
/* - Home Page 5 Css */
/*-----------------------------------------------------------------------------------------*/

.header5 .top-info i{
	font-size: 18px;
    padding-right: 5px;
	
}
.header5 .top-info {
    display: inline-block;
    position: relative;
    font-size: 15px;
    letter-spacing: 0.3px;
    padding-right: 0;
    margin-right: 0;
    vertical-align: middle;
    font-family: 'Open Sans', sans-serif;
    color: #dadada;
    line-height: 20px;
    font-weight: 700;
}
.header5 .top-line{
	background:#001a57;
	padding: 15px 0;
}
.header5 .top-line-left {
    float: left;
}
.header5 .top-line-right {
    float: right;
}
.header5 .top-line-right .top-info a {
    font-size: 20px;
    color: #f2ae2b;
    font-weight: 600;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
.header5 .icon {
    font-size: 18px;
    color: #dadada;
}
.header5 .icon-Pointer:before {
    vertical-align: middle;
    padding-right: 6px;
}

@media(min-width:992px){
	.header5 .tt-header {
		background: transparent;
		margin-bottom: -32px;
	}
	.header_05{
		background: #003366;
		height:64px;
	}
	.header5 .header2.stick {
		background: #001a57;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 0px;
		position:fixed;
	}
	.header5 .tt-header .toggle-block {
		display: block !important;
		float: left;	
	}
	
	.header5 .tt-header .top-social li:last-child {
		display: inline-block;
		margin-right: 18px;
	}
	.header5 .tt-header .main-nav > ul > li > a {
		font-weight: 600;
		font-size: 15px;
		color: #fff;
		text-transform: uppercase;
		padding: 18px 25px;
		font-family: 'Poppins', sans-serif;
		letter-spacing: 0.30px;
	}
	.header5 .tt-header .main-nav > ul > li:hover {
		background: #f2ae2b;
	}
	.header5 .tt-header .main-nav > ul > li.active {
			background: #f2ae2b;
	}
}

.header-right-info ul li .single-header-right-info .icon-box {
    float: left;
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-color: #dddddd;
    text-align: center;
    margin-right: 25px;
    margin-top: 6px;
}
 .header-right-info ul li .single-header-right-info .text-box p, .header-right-info ul li .single-header-right-info .text-box p a {
	color: #898989;
	font-size: 15px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	padding-top: 0;
	text-transform:initial;
	margin-bottom: 0;
}

 .header-right-info ul li .single-header-right-info .icon-box {
     float: left;
     width: 40px;
     height: 40px;
     border: 1px solid;
     border-color: #dddddd;
     text-align: center;
     margin-right: 25px;
     margin-top: 6px;
	 border-radius: 50%;
	 background:#f2ae2b;
}
 .header-right-info ul li .single-header-right-info .icon-box i{
     font-size:20px;
     color:#fff;
     font-size: 18px;
     line-height: 40px;
}

.header5 .header_top ul{
	width: auto;
    margin: 0;
    background: 0;
    box-shadow: none;
	height: auto;
}

.header-right-info ul li {
    display: inline-block;
    vertical-align: middle;
    padding-right: 45px;
    list-style: none;
}
.header-right-info ul li:last-child{
	padding-right:0;
}
.header-right-info ul li .single-header-right-info .text-box, .header-right-info ul li .single-header-right-info .icon-box {
    display: table-cell;
    vertical-align: top;
}
.header-right-info ul li .single-header-right-info .text-box h5 {
	color: #003366;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 0;
    margin-bottom: 2px;
}

.header5 .header_top{
	border-top:0;
	background: #fff;
	padding-top: 25px;
    padding-bottom: 28px;
}
.header5 .sim-button{
	font-weight:700;
	font-size:15px;
}

/* */
.hmslider5 .slide1{
	background-image:url(https://via.placeholder.com/1920x694);
}
.hmslider3 .slide2{
	background-image:url(https://via.placeholder.com/1920x694);
}
.hmslider3 .slide3{
	background-image:url(https://via.placeholder.com/1920x694);
}

.hmslider5 .tt-mslide-entry:before{
	background:#000;
	opacity:0.4;
}
.hmslider5 .slider_content {
    background: none;
    margin: 0;
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: left;
    float: right;
    width: 40%;
}
.hmslider5 .tt-mslide-title p{
	width:100%;
}
/**/

.about_us_05{
	padding-top:91px;
}

.latest_work_05{
	background:url("https://via.placeholder.com/1920x1088") no-repeat;
	background-size:cover;
	position:relative;
	background-attachment:fixed;
}
.latest_work_05:before{
	content:'';
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	background:#003366;
	opacity:0.75;
}
.latest_work_05 .tt-title{
	color:#fff;
	position:relative;
}
.latest_work_05 .row0{
	position:relative;
}
.latest_work_05 .row0 .simple-text p{
	color:#fff;
}
.latest_work_05 ul.portfolio-sorting {
    margin-bottom: 40px;
    position: relative;
}
.latest_work_05 .portfolio-sorting.list-inline>li{
	background:#fff;
	border:0;
}
.latest_work_05 .portfolio-sorting.list-inline>li:hover{
	background: #f2ae2b;
}
.strip_05{
	margin-top:250px;
}
.our_customer_saying_05{
	background:url("http://via.placeholder.com/1920x621") no-repeat;
	background-size:cover;
	z-index:1;
}
.our_customer_saying_05 .owl-carousel .owl-item img{
	width:auto;
	margin:0 auto;
	
}
.our_customer_saying_05 .content{
	text-align:center;
}
.our_customer_saying_05 .our_customer p{
	float:none;
	text-align:center;
	margin-top:15px;
}
.our_customer_saying_05  .our_customer h2{
	float:none;
	text-align:center;
}
.our_customer_saying_05  .our_customer{
	padding: 80px 85px 30px 85px;
	margin-top:-50px;
}

.building_services{
	background:url("https://via.placeholder.com/1920x446") no-repeat;
	background-size:cover;
	position:relative;
	background-attachment:fixed;
	z-index:1;
	background-position:center;
}
.building_services:before{
	content:'';
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:#003366;
	opacity:0.70;
	position:absolute;
}
.building_services h4{
	font-family: 'Poppins', sans-serif;
	font-size:30px;
	font-weight:400;
	color:#f2ae2b;
	text-align:center;
	margin-top:0;
	margin-bottom:15px;
}

.building_services .content{
	position:relative;
}
.building_services h3{
	font-family: 'Poppins', sans-serif;
	font-size:30px;
	font-weight:500;
	color:#fff;
	text-align:center;
	margin-top:0;
	margin-bottom:45px;
}

.building_services .sim-button{
	background:#f2ae2b;
}
.building_services .button8:hover::before{
	opacity:1;
	width:100%
}
.building_services .button8::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: #003366;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 3px;
}
.free_home_enquiry .image{
	float:left;
	width:50%;
}

.free_home_enquiry .image img{ 
	border:4px solid #fff;
	border-left:0;
	box-shadow: 0 8px 43px rgba(0, 0, 0, 0.1);
	height:631px;
	object-fit:cover;
}
.free_home_enquiry .enquiry_form{
	float:left;
	width:50%;
	padding-left:70px;
}

.enquiry_form{
	padding:80px 0;
}

.free_home_enquiry .sim-button{
	margin-top:30px;
}
.free_home_enquiry .button{
	padding-bottom:0;
	text-align:left;
}

.free_home_enquiry .tt-title{
	font-weight:700;
	color: #003366;
    line-height: 36px;
	text-align:left;
	padding-left:15px;
	margin-bottom:0;
}

.free_home_enquiry .form-classes label{
	color:#2a2a2a;
}
.free_home_05  textarea{
	height:105px;
}
@media (max-width: 991px){
.free_home_enquiry .image {
    float: left;
    width: 100%;  
}
.free_home_enquiry .enquiry_form {
    float: left;
    width: 100%;
	padding-left: 0;
}
.free_home_enquiry .image img{
	height:auto;
}
.free_home_enquiry.free_home .tt-title{
	text-align:left;
}

}
@media (max-width: 767px){
.free_home_enquiry .enquiry_form{
	padding:50px 0;
}
.free_home_enquiry.free_home .form-classes label{
	text-align:left;
}
.free_home_enquiry .button{
	text-align:center;
}
.free_home_enquiry.free_home .form-classes form{
	padding-top:0;
}
}

@media (min-width: 992px){
.header5 .header2.stick {
    background: #003366;
    
}

}