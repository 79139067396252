@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

.vc_row-has-fill+.vc_row>.vc_column_container>.vc_column-inner {
	padding-top: 0;
}

.qc-cmp-alert-publisher-logo-image, .qc-cmp-publisher-logo { width: 100% !important; }

.row:before, .row:after {
	display: inline-block;
}

body {
	font-family: Poppins, sans-serif;
	font-size: 14px;
	color: #2b3d4b;
	-webkit-font-smoothing : antialiased;
}
body.error404 .page-header-banner {
	display: none;
}
body.error404 main{
	height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-header-banner {
	margin: 0 -15px;
}
.strip h1, .strip .h4 {
    color: #354a50;
    font-family: Poppins,sans-serif;
    text-align: center;
}
.strip .h4 {
	font-size: 14px;
    font-weight: 400;
}
.strip h1 {
    font-size: 36px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
}
.tt-title {
	margin-bottom: 0;
}
a:hover {
	text-decoration: none;
}
a.btn {
	text-transform: uppercase;
	padding: 0;
	line-height: inherit;
	font-weight: 600;
	border-radius: 3px;
}

a.btn-white {
	color: #fff;
	padding: 10px 20px;
	background: #d2cdc7;
}

a.btn-red {
	color: #fff;
	padding: 10px 20px;
	background: #ca4357;
}

.ch-cta .button8, .ch-cta .button8 a {
	color: #fff;
}

.button8, .button8 a {
	color: #354a50;
}
.button8:hover, .button8:hover a {
	color: white;
}

#breadcrumbs a {
    color: #2b3d4b;
}

.tt-header .main-nav {
	display: flex;
	align-items: center;
}

.tt-header .main-nav>ul>li>a {
	padding: 30px 20px;
}
.tt-header .main-nav>ul>li>ul>li>a:hover {
	background-color: #354a50;
}

.vc_row-has-fill>.vc_column_container>.vc_column-inner {
	padding-top: 0;
}

.ch-btn .sim-button.button8 {
    width: inherit;
    max-width: 250px;
    margin: 0;
}

.about_technologies {
	z-index: 0;
	background: transparent;
	.technologies {
		background: transparent;
		.icon {
			min-height: 36px;
		}
	}
}
.about_technologies:before {
	background: transparent;
}
.technologies:not(.sectorslider) .single-sec {
    border-bottom: 8px solid #f2ae2b;
    padding-top: 75px;
    padding-left: 34px;
    padding-right: 34px;
    p {
    	padding-bottom: 0;
    }
}

.about_us_features .about_us1 {
	border: none;
	p {
		margin-top: -55px;
	}
}

ul:not(.nav):not(.categoryList) {
	padding-left: 30px;
	list-style: none;
	li:before {
	    content: "\f00c";
	    position: absolute;
	    font-family: "Font Awesome 5 Free";
    	font-weight: 900;
	    left: 18px;
	    color: #f2c21a;
	}
}

.page-header-banner {
	padding: 118px 15px 150px 15px;
	text-align: center;
	position: relative;
	.page_title {
		color: white;
		font-size: 42px;
		font-weight: 700;
	}
	.breadcrumb {
		padding: 15px 50px;
	    position: absolute;
	    left: 0;
	    bottom: -40px;
	    right: 0;
	    background: white;
	    p {
	    	margin-bottom: 0;
	    }
	}
}

.ch-homebanner {
	margin: 0 auto;
}

.technologies {
	background: white;
}

.request_A_quote {
	.row > div:last-child {
		display: flex;
	    align-items: center;
	    justify-content: flex-end;
	}
}

.sectorslider {
	.sim-button {
		width: 205px;
	}
}

header a.btn-red {
	margin-left: 15px;
	position: relative;
}
header a.btn-red span {
	position: relative;
	z-index: 1;
}
header a.btn-red:hover {
	border-color: #e9d480;
}
header a.btn-red:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 0;
	height: 100%;
	background: #e9d480;
	opacity: 0;
	transition: 0.3s all;
}
header a.btn-red:hover:before {
	width: 100%;
	opacity: 1;
}

.header_top_02 {
	ul {
		padding-left: 0 !important;
	}
	ul li {
		padding-right: 50px;
	}
	img {
		margin-right: 10px;
	}
}
header ul {
	padding: 0;
	li:before {
		display: none;
	}
}

ul.sub-menu {
    padding-left: 0 !important;
}

.ch-services {
	.row.top {
	    align-items: center;
	    position: relative;
	    a {
	    	position: absolute;
	    	right: 0;
	    }
	}
}

.image-opacity-on-hover {
    background: rgba(233, 212, 128, 1);
}
.image-opacity-on-hover:hover img {
	opacity: 0.25;
}
.image.image-opacity-on-hover.image-zoom-on-hover {
	position: relative;
	height: 180px;
}
.image.image-opacity-on-hover.image-zoom-on-hover:hover:before {
	content: "EN SAVOIR PLUS";
	position: absolute;
	z-index: 1;
    left: 50%;
    top: 50%;
    color: white;
    font-weight: 600;
    transform: translate(-50%, -75%);
}
.image.image-opacity-on-hover.image-zoom-on-hover:hover:after {
    content: url(http://localhost/CHAPUZET/wp-content/uploads/2018/11/PICTO-Plus.png);
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 75%);
}

.ch-cta {
	.sim-button {
		width: auto;
		padding: 0 20px;
	}
}

.ch-certif {
	border-top: 1px solid #d2cdc7;
	padding: 40px 0;
	.item {
		display: flex;
		align-items: center;
		margin: 10px 0;
		
		img {
			width: 51px;
			height: auto;
		}
		
		.content {
			margin-left: 15px;
			flex-basis: calc(100% - 51px);
			h3 {
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}

.ch-contentblock {
	.col-md-6 {
		background: white;
		padding: 80px 50px;
	}
	.grey {
		background: #f5f4f3;
	}
	h2 {
		font-size: 18px;
		font-weight: 600;
	}
}

.ch-offre .col-md-4 {
	padding: 0 4px;
}

.ch-offercard-item {
	background: white;
	.title-wrapper {
		padding: 30px 100px;
		h2 {
			color:  #2b3d4b;
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 10px;
			text-transform: uppercase;
		}
		p {
			margin-bottom :0;
		}
	}
	.highlight-wrapper {
		padding: 30px 100px;
		background: #e9d480;
		p {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 0;
		}
	}
	.content-wrapper {
		padding: 30px 100px;
		a {
			margin-top: 30px;
			white-space: pre-wrap;
			background: #e9d480;
			color: #2b3d4b;
			position: relative;
			border: none;
		}
		a span {
			position: relative;
			z-index: 1;
			color: #354a50 !important;
		}
		a:hover span {
			color: white !important;
		}
		a:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			width: 0;
			height: 100%;
			background: #354a50;
			opacity: 0;
			transition: 0.3s all;
		}
		a:hover:before {
			width: 100%;
			opacity: 1;
		}
	}
}

.ch-contentblock a.btn.btn-white {
	background: #e9d480;
	color: #2b3d4b;
	border: none;
	position: relative;
}

.ch-contentblock a.btn.btn-white span {
	position: relative;
	z-index: 1;
	color: #354a50 !important;
}
.ch-contentblock a.btn.btn-white:hover span {
	color: white !important;
}
.ch-contentblock a.btn.btn-white:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 0;
	height: 100%;
	background: #354a50;
	opacity: 0;
	transition: 0.3s all;
}
.ch-contentblock a.btn.btn-white:hover:before {
	width: 100%;
	opacity: 1;
}

.services_01 .single_service .content {
	padding-bottom: 40px;
	a {
		background-color: #e9d480;
		margin-top: 30px;
	}
	h2 {
		min-height: 48px;
	}
	h2, p {
		text-align: center;
	}
}

.ch-offre a.btn.btn-white {
	border: none;
	position: relative;
	transition: 0.3s color;
	span {
		position: relative;
		z-index: 1;
	}
}
.ch-offre a.btn.btn-white:hover {
	color: white;
}
.ch-offre a.btn.btn-white:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 0;
	height: 100%;
	background: #354a50;
	opacity: 0;
	transition: 0.3s all;
}
.ch-offre a.btn.btn-white:hover:before {
	width: 100%;
	opacity: 1;
}

.ch-services .single_service .content {
	min-height: 250px;
}

.ch-services .single_service .simple-text p,
.ch-services .single_service .sub-title {
    text-align: left !important;
}

.ch-offre .single_service .content {
    padding: 40px 75px;
}

.services_01 .owl-nav,
.owl.nav {
	display: flex;
	justify-content: flex-end;
}
.owl-prev, .owl-next {
    display: inline-block;
    padding: 5px 13px;
    background: #f6f6f6;
    border-radius: 3px;
    margin: 30px 0 0 10px;
}
.owl-dots {
	margin-top: -25px;
	.owl-dot {
		display: inline-block;
		position: relative;
		span {
			height: 8px;
		    width: 8px;
		    display: inline-block;
		    background: #d2cdc7;
		    border-radius: 8px;
		    margin-right: 15px
		}
	}
	.owl-dot.active span {
		background: #e9d480;
	}
	.owl-dot.active span:before {
		width: 20px;
	    height: 20px;
	    content: '';
	    border: 1px solid #e7d18b;
	    display: inline-block;
	    left: -6px;
	    top: 1px;
	    position: absolute;
	    border-radius: 16px;
	}
}
.ch-projet {
	.ch-projet-item {
	    padding: 0 4px;
	    margin-bottom: 4px;
	    overflow: hidden;
	    transition: all 0.3s;
	    cursor: pointer;
	    div {
	    	transition: all 0.3s;
	    	position: relative;
	    	z-index: -1;
	    }
	}
	.ch-projet-item:hover {
		background: rgba(233, 212, 128, 0.6);

	}
	.ch-projet-item:hover:before {
		content: "VOIR LE CHANTIER";
	    position: absolute;
	    z-index: 1;
	    left: 50%;
	    top: 50%;
	    color: #fff;
	    font-weight: 600;
	    transform: translate(-50%,-75%);
	}
	.ch-projet-item:hover:after {
		content: url(http://localhost/CHAPUZET/wp-content/uploads/2018/11/PICTO-Plus.png);
	    position: absolute;
	    z-index: 1;
	    left: 50%;
	    top: 50%;
	    transform: translate(-50%,75%);
	}
	.ch-projet-item:hover > a > div {
		transform: scale(1.15);
	}
	.ch-projet-btn {
		margin-top: 30px;
		color: #fff;
		background: #e9d480;
		text-transform: uppercase;
		padding: 10px 20px;
		font-weight: 700;
		border-radius: 3px;
	}
}

.ch-contact-form input, .ch-contact-form textarea, .ch-contact-form select {
    width: 100%;
    margin-top: 25px;
    padding: 10px 20px;
    -webkit-appearance: none;
    border-radius: 0;
    background: transparent;
    border: 1px solid #ccc;
}
.ch-contact-form input::placeholder, .ch-contact-form textarea::placeholder {
	color: black;
}
.ch-contact-form input[type="submit"] {
	padding: 10px 20px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	color: white;
    background: #ca4357;
    border: none;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    width: auto;
}

input::placeholder, textarea::placeholder {
    color: grey !important;
    font-style: italic;
}

.wpcf7-checkbox > span:not(.last) {
    margin: 0 30px 0 0;
}
span.wpcf7-list-item.last {
    margin-left: 0;
}
.form-text {
	margin-bottom: -30px;
    margin-top: 20px;
}

.pdfBlock {
	background: #e9d480;
	border: none;
	padding: 0;
	.pdfSize {
		text-align: center;
	}
	 a {
		padding: 15px 25px;
		display: inline-block;
		position: relative;
		width: 100%;
	}
}
.pdfBlock .pdfSize > span {display: block;}
.pdfBlock a span {
	position: relative;
	z-index: 1;
	color: #354a50 !important;
}
.pdfBlock a:hover span {
	color: white !important;
}
.pdfBlock a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 0;
	height: 100%;
	background: #354a50;
	opacity: 0;
	transition: 0.3s all;
}
.pdfBlock a:hover:before {
	width: 100%;
	opacity: 1;
}

.service_details .tt-title {
    font-size: 28px;
}

.service_details .blogAside .categoryList li a i {
	float: none;
	position: absolute;
	right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

footer {
	* {
		color: #fff;
	}
	.footer-top {
		background: #243337;
		padding: 80px 0;
		h3 {
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
		}
		a {
			font-weight: 600;
			line-height: 35px;
			font-size: 16px;
		}
		a:hover {
			color: #fff;
		}
	}
	.footer-bottom {
		position: relative;
		padding: 20px 0;
		background: #354a50;
		a {
			color: #9e9e9e;
			text-decoration: underline;
		}
		p {
			margin-bottom: 0;
			font-size: 13px;
		}
	}
	.container {
		position: relative;
		.a42 {
			position: absolute;
			right: 15px;
			top: 0;
		}
	}
}
footer .fixed-phone {
	z-index: 9999;
	position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(-100%);
    display: flex;
    justify-content: flex-end;
 	.content {
 		display: flex;
    	align-items: center;
 		background: #354a50;
	    font-weight: 600;
	    padding-right: 15px;
 	}
    img {
    	padding: 10px;
    	background: #e9d480;
    }
    p {
    	margin-bottom: 0;
    	margin-left: 15px;
    	font-size: 16px !important;
    }
    a {
    	color: white;
    	text-decoration: none;
    }
}

@media(min-width: 768px) {
	.services_01 .top .simple-text p {
		padding: 0 100px;
	}
}

@media (max-width: 991px) {
	.tt-header .main-nav,
	ul.nav {
		flex-direction: column;
		text-align: center;
	}
	.tt-header .toggle-block {
	    background: white;
	}
	.tt-header .main-nav > ul > li > a,
	.tt-header .main-nav > ul > li > a:focus {
		color: #354a50;
		padding: 15px 20px !important;
	}
	.tt-header .main-nav ul > li.active > a,
	.tt-header .main-nav ul > li.select > a,
	.tt-header .main-nav ul > li > a:hover {
		color: #354a50 !important;
		background: transparent !important;
		font-weight: bold;
	}
	header a.btn-red {
		margin: 20px 0 !important;
	}
	.tt-header {
		width: 100vw;
	}
	.header2 .tt-header .top-inner:after,
	.header2 .tt-header .top-inner:before {
		background: white;
	}
	.header2 .tt-header .cmn-toggle-switch:after,
	.header2 .tt-header .cmn-toggle-switch:before,
	.header2 .tt-header .cmn-toggle-switch span {
		background: #354a50;
	}
	li.menu-item-has-children:hover ul.sub-menu {
		display: block;
		background: transparent;
		a {
			color: #354a50;
			font-size: 13px;
		}
	}
	.ch-offercard-item .content-wrapper,
	.ch-offercard-item .title-wrapper {
		padding: 30px;
	}
	.ch-contentblock .col-md-6 {
		padding: 50px 30px;
	}
	.ch-contentblock .col-md-6:not(.wte):not(.grey) {
		min-height: 200px;
	}
	.ch-contentblock a.btn.btn-white {
		white-space: pre-wrap;
	}
}

@media(max-width: 425px) {
	main {
		margin-top: 73px;
	}
	.ch-homebanner {
		padding: 0;
	}
	.ch-services .row.top a {
		position: relative;
		margin: 30px auto 0 auto;
	}
	.services_01 .tt-title {
		width: 100%;
	}
	.services_01 .simple-text p {
		width: 100%;
	}
	.services_01 .owl-nav,
	.services_01 .owl-dots {
		padding: 0 15px;
	}
	.request_A_quote .button_request {
	    float: none;
	    margin: 20px auto 0 auto;
	}
	.page-header-banner .breadcrumb {
		width: 100%;
	}
	footer .container .a42 {
		position: relative;
		float: right;
	}
}